import React,{ Component, Fragment } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import duix from 'duix';
import {t} from './components/Translate';

// Context
import { StoreContext } from './context/StoreContext';

// Views
import Home from './views/Home';
import Print from './views/Print';
import About from './views/About';
import Products from './views/Products';
import Pending from './views/Pending';
import Product from './views/Product';
import Contact from './views/Contact';
import CartPage from './views/CartPage';
import WishlistPage from './views/WishlistPage';
import Krcko from './views/Krcko';
import Howto from './views/Howto';
import Register from './views/Register';
import Rules from './views/Rules';
import Shipping from './views/Shipping';
import News from './views/News';
import NewsSingle from './views/NewsSingle';
import Success from './views/Success';
import Fail from './views/Fail';
import Message from './views/Message';
import Activation from './views/Activation';
import UpdatePassword from './views/UpdatePassword';
import NoMatch from './views/NoMatch';
import Shop from './views/Shop';
import TermsOfUse from './views/TermsOfUse';
import PrivacyPolicy from './views/PrivacyPolicy';
import Pages from './views/Pages';

import 'react-notifications/lib/notifications.css';
import {NotificationContainer} from 'react-notifications';




import Banners from './components/Banners';
import Header from './components/Header';
import Footer from './components/Footer';
import {Auth} from './components/Auth';
import {Store} from './components/Store';
import Modal from './components/Modal';
import {Cart} from './components/Cart';
import AdultCheck from './components/AdultCheck';

import ScrollToTop from 'react-router-scroll-top';
import CookieConsent from "react-cookie-consent";


import {withRouter} from 'react-router-dom';

//import ReactGA from 'react-ga';
import Service from './components/Service';

const lsPrefix = 'brntshp'+window.location.hostname.split('.')[0];

Cart.initCart();

const checkLoginStatus = () => {
    
  if (localStorage.getItem(lsPrefix+'token') && localStorage.getItem(lsPrefix+'userId')) {
    Auth.isAuthenticated = true;
    Auth.token = localStorage.getItem(lsPrefix+'token');
    Auth.userId = localStorage.getItem(lsPrefix+'userId');
    Auth.name = localStorage.getItem(lsPrefix+'name');
    Auth.email = localStorage.getItem(lsPrefix+'email');
    duix.set('registration', 1);
    console.log('chd');
  }else {
      Auth.isAuthenticated = false;
  }
  // currency
  if (localStorage.getItem(lsPrefix+'currency')) {
    Auth.currency = localStorage.getItem(lsPrefix+'currency');
  }
}

const getStore = () =>{
  Service.get('/store/get',{ 
  },(res)=>{
      Store.id = res.data.id;
      Store.name =res.data.name;
      Store.description = res.data.description;
      Store.status = res.data.status;
      Store.shippingInfo = res.data.shipping_info;
      Store.lang = res.data.lang;
      Store.langAdmin = res.data.lang_admin;
      Store.createdAt = res.data.created_at;
      Store.updatedAt = res.data.updated_at;
      Store.shippingPriceSerbia = res.data.shipping_price_serbia;
      Store.shippingPriceAfrica = res.data.shipping_price_africa;
      Store.shippingPriceAsia = res.data.shipping_price_asia;
      Store.shippingPriceAntarctica = res.data.shipping_price_antarctica;
      Store.shippingPriceEurope = res.data.shipping_price_europe;
      Store.shippingPriceOceania = res.data.shipping_price_oceania;
      Store.shippingPriceNorthAmerica = res.data.shipping_price_north_america;
      Store.shippingPriceSouthAmerica = res.data.shipping_price_south_america;
      Store.shippingFreeLimit = res.data.shipping_free_limit;
      Store.logo = res.data.logo;
  }); 
}

const PrivateRoute = ({ component: Component, ...rest }) => (
    
  <Route {...rest} render={(props) => (
    //   console.log(props.location),
    Auth.isAuthenticated === true
      ? <Component {...props} />
      : <Redirect to={{
          pathname: '/registracija'+props.location.pathname,
          state: { from: props.location }
        }} />
  )} />
)

checkLoginStatus();
getStore();

class App extends Component {
    static contextType = StoreContext;
    constructor(props){
        super(props);
        this.header = React.createRef();
        this.state = {
          //stylePath: 'https://api.shop.brainit.rs/getIndexCss?o='+window.location.host+'&u='+Auth.userId, 
          //styleMobilePath: 'https://api.shop.brainit.rs/getMobileCss?o='+window.location.host+'&u='+Auth.userId
          // stylePath: Service.api+'/getIndexCss?o='+window.location.host+'&u='+Auth.userId, 
          // styleMobilePath: Service.api+'/getMobileCss?o='+window.location.host+'&u='+Auth.userId,
        };
    }

    componentDidMount(){
        checkLoginStatus();
       // ReactGA.initialize(Service.gaCode);
       // ReactGA.pageview(window.location.pathname + window.location.search)
      }

    componentDidUpdate(prevProps) {
        checkLoginStatus();
        if (this.props.location.pathname !== prevProps.location.pathname) {
            console.log('Route change!');
           // ReactGA.initialize(Service.gaCode);
           // ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    componentWillReceiveProps(newProps){
        checkLoginStatus();
        if(this.props != newProps){
            console.log('update app');
           // ReactGA.initialize(Service.gaCode);
           // ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    updateCartDisplay=()=>{
        this.header.current.updateCartDisplay();
    }

    updateRegistration=()=>{
        this.header.current.updateRegistration();
    }

  render() {

    return (
      <div className="wrapper">
        <link rel="stylesheet" type="text/css" href={this.state.stylePath} />
        <link rel="stylesheet" type="text/css" href={this.state.styleMobilePath} />

        <Router>
            {/* Adult check and cookie consent */}
            {this.context.store.adult_check === 1 ?
              <AdultCheck />
              :
              <CookieConsent
                  location="bottom"
                  buttonText={this.context.store.lang === 'en' ? 'I understand' : this.context.store.lang === 'sr' ? 'Prihvatam' : null}
                  cookieName="ppCookie"
                  style={{ background: 'black', color: 'white', fontSize: '1em',  boxShadow: '0px -5px 10px rgba(0, 0, 0, .3)' }}
                  buttonStyle={{background: 'white', color: 'black', fontSize: "16px", fontWeight: 'bold' }}
                  expires={150}
              >
                {this.context.store.lang === 'en' &&
                  <Fragment>
                    The site uses cookies that do not contain personal information and serve to improve the user experience of our site. Presence on the site implies that visitors agree to the use of these cookies. For more details on data protection, read in <a href="/pravila" style={{ textDecoration: 'underline'}} > Privacy policy</a>.
                  </Fragment>
                }

                {this.context.store.lang === 'sr' &&
                  <Fragment>
                    Ovaj sajt koristi kolačiće koji ne čuvaju personalne informacije o korisniku i služe da poboljšaju i ubrzaju korišćenje sajta. Korišćenjem sajta prihvatate korišćenje kolačića. Za više informacija idite na link<a href="/pravila" style={{ textDecoration: 'underline'}} > Pravila privatnosti</a>.
                  </Fragment>
                } 
              </CookieConsent>
            }

            <ScrollToTop>
            {/* Header */}
            {(this.props.location.pathname && this.props.location.pathname.slice(0,6) !== '/admin') &&
              <Header history={this.props.history} ref={this.header} /> 
            }

          <Switch>
            <Route exact path="/" component={Home} />
            <PrivateRoute exact path="/za-stampu/:file?" component={Print} />
            <Route path="/u-pripremi" component={Pending} />
            <PrivateRoute path="/lista-zelja" component={WishlistPage} />
            <Route path="/kako-kupovati" component={Howto} />
            <Route path="/pravila" component={Rules} />
            <Route path="/dostava" component={Shipping} />
            <Route path="/vesti" component={News} />
            <Route path="/vest/:id/:slug?" component={NewsSingle} />
            <Route path="/message/:message?" component={Message} />
            <Route path="/success/:oid?/:ammount?/:tid?" component={Success} />
            <Route path="/fail" component={Fail} />
            <Route path="/banners" component={Banners} />
            <Route path="/contact" component={Contact} />
            <Route path="/registracija/:message?/:value?" render={(props) => <Register {...props} updateRegistration={this.updateRegistration} />} />
            <Route path="/cart" render={(props) => <CartPage {...props} updateCartDisplay={this.updateCartDisplay} />} />
            <Route path="/terms-of-use" component={TermsOfUse} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/activation/:activationCode" component={Activation} />
            <Route path="/reset-password/:hash/:userId" component={UpdatePassword} />
            <Route path="/shop/:filter?/:a?/:b?" component={Shop} />
            <Route path="/product/:id/:slug?" render={(props) => <Product {...props} updateCartDisplay={this.updateCartDisplay} />} />
            <Route path="/:slug" component={Pages} />
            <Route component={NoMatch} />
          </Switch>
            
          {/* Footer */}
          {this.props.location.pathname && this.props.location.pathname.slice(0,6) !== '/admin' &&
            <Footer />
          }

          <Modal />
          </ScrollToTop>
          <NotificationContainer/>
        </Router>
      </div>
    );
  }
}

export default withRouter(props => <App {...props}/>);
//export default App;
