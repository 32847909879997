import React, { Component, useEffect, useState } from 'react';
import Service from '../components/Service';
import renderHTML from 'react-render-html';

export default function Pages(props){
    const { slug, handleSlug } = props.match.params;
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [featuredImageUrl, setFeaturedImage] = useState('')
    useEffect(()=>{
        getPage();
    },[slug])

    const getPage=()=>{
        Service.get("/store/getPage",{
            slug
        }, (res) => {
            if(res.success){
                if(res.page.title){
                    setTitle(res.page.title)
                    setContent(res.page.content)
                    setFeaturedImage(res.page.images[0].image)
                } 
            }
        });
    }
    return(
        <div>
            <div className="top">
                <div className="page-featured-image">
                    {featuredImageUrl ?
                        <img src={featuredImageUrl} />
                        :
                        null
                    }
                </div>
            </div>
                <div className="content">
                    {/* <h1 className="color-header">{this.state.title}</h1> */}
                    <div className="page-content">
                        {renderHTML('<div>'+content+'</div>')}
                    </div>
                </div>
        </div>
    )
}
