// import Service from '../components/Service';

export const Store = {
  id: false,
  name: false,
  description: false,
  status: false,
  shippingInfo: false,
  lang: false,
  langAdmin: false,
  createdAt: false,
  updatedAt: false,
  shippingPriceSerbia: false,
  shippingPriceAfrica: false,
  shippingPriceAsia: false,
  shippingPriceAntarctica: false,
  shippingPriceEurope: false,
  shippingPriceOceania: false,
  shippingPriceNorthAmerica: false,
  shippingPriceSouthAmerica: false,
  shippingFreeLimit: false,
  logo: false,

//   authenticate(password, cb, cb2){

//     Service.get('/admin/store/get',{ 
//     },(res)=>{
//             this.id = res.data[0].id;
//             this.name =res.data[0].name;
//             this.description = res.data[0].description;
//             this.status = res.data[0].status;
//             this.createdAt = res.data[0].created_at;
//             this.updatedAt = res.data[0].updated_at;
//     }); 
//   }
}