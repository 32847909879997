import React, { Component, useRef } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Service from '../components/Service';

class Banners extends Component {
    constructor(){
        super();
        this.state={
            banners: [] ,
            storeId: 3,
            links: [],
            autoplay: false
        }
    }

    componentDidMount(){
        this.getImages();
    }

    linkTo = (index) => {
        if(this.state.links[index]){  
            window.location.href = this.state.links[index];
        } 
    }
    

    getImages=()=>{
        if(this.props.page === "product"){
            Service.get("/products/getGallery",{
                productId: this.props.productId
            }, (res) => {
                this.setState({ banners: res.images },()=>{
                    this.setState({autoplay: true },()=>{
                    });
                })
            });
        }else{
            Service.get("/store/getBanners",{}, (res) => {
                if(res.success){
                    let banners = [];
                    let l = [];
    
                    res.banners.map(function(item){
                        l.push(item.link);
                        if(window.screen.width > 600 && item.small_device === 0){
                            banners.push({image: item.image})
                        }
                        if(window.screen.width <= 600 && item.small_device === 1){
                            banners.push({image: item.image})
                        }
                    })
                    
                    this.setState({banners, links: l, autoplay: true});
                }
            });
        }
    }

    render() {
        return (     
            <Carousel showThumbs={false} showStatus={false} interval={6000} infiniteLoop={true} autoPlay={this.state.autoplay} onClickItem={(e)=>{this.linkTo(e)}}>
                {this.state.banners.map(function (item, index) {
                    return (
                        <div className={this.props.page === "product" ? "product-image-container" : null} key={index}> 
                            <img src={item.image} /> 
                        </div>    
                    )
                },this)}
            </Carousel>
        );
    }
};

export default Banners;