import React, { useEffect, useState, useContext, Fragment } from 'react';
import Service from '../components/Service';
import { Link } from 'react-router-dom';
import Banners from '../components/Banners';
import { Cart } from '../components/Cart';
import T from '../components/Translate';
import { StoreContext } from '../context/StoreContext';
import { useHistory } from "react-router";

// import ProductView from '../components/ProductView';
// import ProductSlider from '../components/ProductSlider';
// import TopProductSlider from '../components/TopProductSlider';
// import ImageSlider from '../components/ImageSlider';
// import NewsSlider from '../components/NewsSlider';
// import {Store} from '../components/Store';
// import ReactGA from 'react-ga';
// import Slider from '../components/Slider';
// import Sticker from '../components/Sticker';
// import {Themes} from '../themes/ThemesJson';

export default function Home(){
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const store = useContext(StoreContext);
  const history = useHistory();

  useEffect(()=>{
    getFeaturedProducts();
  },[])

  const getFeaturedProducts=()=>{
    Service.get("/products/getFeatured",{},(res) => {
      setFeaturedProducts(res.products)
    });
  }

  const addToCart=(e)=>{
    let productId = e.target.getAttribute('data-product-id');

    Cart.addToCart(productId);
  }

  // handle a href from custom HTML
  const contentClickHandler = (e) => {
    let targetLink = e.target.closest('a');
    if(!targetLink) return;
    e.preventDefault();
    
    var param = targetLink.href.split('/');
    
    history.push('/'+param[3])
  };

  return(
    <div>
      {/* Banners home */}
      <div className="top">
        <Banners />
      </div>

      {/* Content home */}
      <div className="content home">
        <h1 className="color-titles" id="title-center">{store.store.name}</h1>
        <h2 className="subtext" id="subtext-center">{store.store.description}</h2> 
        
        {/* Custom top home HTML */}
        {store.store.home_top_html &&
          <div dangerouslySetInnerHTML={{__html: store.store.home_top_html}} onClick={(e)=>{contentClickHandler(e)}}></div>
        }
        
        {/* Featured products */}
        {(store.store.type == 1 || store.store.type == 2) &&
          <div className="home-products home-products-container">
          {featuredProducts.map(function(item){
            return(
              <div className="home-product" key={item.id}>
                  <a href={`/product/${item.id}/${item.slug}`}>
                    {/* <div className="home-product-image" style={{backgroundImage: `url(${item.image})`}}></div> */}
                    <img className="product-image-home" src={item.image} />
                  </a>

                  <div className="home-product-info">
                    <p className="product-title"><strong>{item.name}</strong></p>
                    <p className="product-description">{item.short_description}</p>
                    <div className="home-product-price">{Service.price(item.price)}</div>
                  </div>

                  {store.store.type === 1 &&
                    <Fragment>
                      {item.quantity_status === 1 && item.quantity > 0 ? 
                          <Link to="/contact"><div className="shop-button"><T text="contactUsButton"/></div></Link>
                      : item.quantity_status === 1 && item.quantity === 0 ?
                        <div className="shop-button"><T text="soldOutButton"/></div>
                      : <div className="shop-button" onClick={(e)=>{addToCart(e)}} data-product-id={item.id}><T text="addToCartButton"/><i className="fas fa-cart-plus"></i></div>
                      }
                    </Fragment>
                  }              

                  {store.store.type === 2 &&
                    <Fragment>
                        <Link to={`/product/${item.id}/${item.slug}`}>
                            <div className="shop-button"><T text="readMoreButton"/></div>
                        </Link>
                    </Fragment>
                  }
                </div>
                )
            },this)}
          </div>
        }

        {/* Custom bottom home HTML */}
        {store.store.home_bottom_html &&
          <div dangerouslySetInnerHTML={{__html: store.store.home_bottom_html}} onClick={(e)=>{contentClickHandler(e)}}></div>
        }
      </div>
    </div>
  )
}