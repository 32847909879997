import React, { Fragment, useEffect, useState, useReducer, useContext } from 'react';
import { Link } from 'react-router-dom';
import T from './Translate';
import renderHTML from 'react-render-html';
import Service from '../components/Service';
import { StoreContext } from '../context/StoreContext';
import { useHistory } from "react-router";

const initialState = {};

function reducer(state, action) {
    switch (action.type) {
        case 'contactAddress':
        case 'contactPhone':
        case 'contactZipCode':
        case 'contactMunicipality':
        case 'contactCountry':
            {
                return {
                    ...state,
                    [action.type]: action.initialState,
                  };
            }
        default:
            throw new Error();
    }
}


export default function Footer(){
    const [facebookUrl, setFacebookUrl] = useState('');
    const [instagramUrl, setInstagramUrl] = useState('');
    const [youtubeUrl, setYoutubeUrl] = useState('');
    const [headerCustomPages, setHeaderCustomPages] = useState([]);
    const [contact, dispatch] = useReducer(reducer, initialState);
    const store = useContext(StoreContext);
    const history = useHistory();

    useEffect(()=>{
        getSocialNetworks();
        getHeaderCustomPages();
        getContactInfo();
    },[]);

    const getSocialNetworks=()=>{
        Service.get("/store/getSocial",{}, (res) => {
            if(res.success){
                setFacebookUrl(res.social[0].facebook_url);
                setInstagramUrl(res.social[0].instagram_url)
                setYoutubeUrl(res.social[0].youtube_url)
            }
        });
    }

    const getHeaderCustomPages=()=>{
        Service.get("/store/getHeaderCustomPages",{}, (res) => {
            if(res.success){
                setHeaderCustomPages(res.header);
            }
        }); 
    }

    const getContactInfo=()=>{
        Service.get('/store/getContactInfo',{},(res)=>{
            if(res.success){
                dispatch({type: 'contactAddress', initialState: res.contact.contact_address})
                dispatch({type: 'contactPhone', initialState: res.contact.contact_phone})
                dispatch({type: 'contactZipCode', initialState: res.contact.contact_zip_code})
                dispatch({type: 'contactMunicipality', initialState: res.contact.contact_municipality})
                dispatch({type: 'contactCountry', initialState: res.contact.contact_country})
            }
        })
    }

    // const moveFooter=()=>{
    //     let el = document.getElementById("footer");
    //     let dh = window.screen.height;
    //     let fh = document.getElementById('footer').clientHeight;
    //     let offsetTop = document.getElementById('footer').offsetTop;

    //     if(offsetTop < dh - fh){
    //         el.style.position = "absolute";
    //         el.style.top = (dh - fh)+'px';
    //     }
    // }
    
    // handle a href from custom HTML
    const contentClickHandler = (e) => {
        let targetLink = e.target.closest('a');
        if(!targetLink) return;
        e.preventDefault();
        
        var param = targetLink.href.split('/');
        
        history.push('/'+param[3])
    };

    const getYear=()=>{
        return new Date().getFullYear();
    }

    return(
        <div className="footer" id="footer">
            {/* Footer top */}
            <div className="footer-top">
                <h3>{store.store.company_name}</h3>
                <p>{contact.contactAddress && contact.contactAddress + ','} {contact.contactZipCode && contact.contactZipCode} {contact.contactMunicipality && contact.contactMunicipality}{(contact.contactZipCode || contact.contactMunicipality) && ','} {contact.contactCountry && contact.contactCountry} {contact.contactPhone && contact.contactPhone}</p>
            </div>

            {/* Footer custom HTML */}
            {store.store.footer_html &&
                <div dangerouslySetInnerHTML={{__html: store.store.footer_html}} onClick={(e)=>{contentClickHandler(e)}}></div>
            }

            {/* Footer left */}
            <div className="footer-bottom-left">
                <ul>
                    <li><Link to="/shop"><T text="headerShop"/></Link></li>
                    {headerCustomPages.length > 0 &&
                        headerCustomPages.map(function(item, index){
                            return(
                                <Fragment>
                                    {item.title &&
                                        <li><Link to={item.slug}>{item.title}</Link></li>  
                                    }
                                </Fragment>
                            )
                        },this)
                    }
                    <li><Link to="/contact"><T text="headerContact"/></Link></li>
                </ul>
            </div>
	        <hr/>

            {/* Footer social */}
            <div className='social'>
                {youtubeUrl &&
                    <a href={youtubeUrl} target="_blank"><i className="fab fa-youtube-square"></i></a>
                }
                {facebookUrl &&
                    <a href={facebookUrl} target="_blank"><i className="fab fa-facebook-square"></i></a>
                }
                {instagramUrl &&
                    <a href={instagramUrl} target="_blank"><i className="fab fa-instagram"></i></a>
                }
            </div>

            {/* Footer bottom */}
            {store.store.type === 1 &&
                <div className="footer-bottom">
                    <div className="tou-footer"><a href="/terms-of-use"><T text="termsOfUseFooter"/></a></div> | <div className="pp-footer"><a href="/privacy-policy"><T text="privacyPolicyFooter"/></a></div>
                </div>
            }

            {(store.store.payment_cc === 1 && store.store.payment_type === 1) &&
                <div className='chipcard-payment'>
                    <div className='chipcard-payment-left'>
                        <a href='http://www.e-services.rs/' target='_blank'><img src='/images/chipcard-payment/chipcard.jpg' /></a>
                        <a href='http://www.mastercard.com/rs/consumer/credit-cards.html' target='_blank'>
                            <img src='/images/chipcard-payment/mastercard_securecode.gif' />
                        </a>
                        <a href='https://rs.visa.com/pay-with-visa/security-and-assistance/protected-everywhere.html' target='_blank'>
                            <img src='/images/chipcard-payment/visa_secure.jpg' />
                        </a>
                    </div>

                    <div className='chipcard-payment-right'>
                        <img src='/images/chipcard-payment/mastercard.png' />
                        <img src='/images/chipcard-payment/maestro.png' />
                        <img src='/images/chipcard-payment/visa.png' />
                        <img className='dina-card' src='/images/chipcard-payment/dina.jpg' />
                    </div>
                </div>
            }

            {(store.store.payment_cc === 1 && store.store.payment_type === 2) &&
                <div className='intesa-payment'>
                    <div className='intesa-payment-left'>
                        <a href='http://www.mastercard.com/rs/consumer/credit-cards.html' target='_blank'><img src='/images/intesa-payment/mastercard_secure.png' /></a>
                        <a href='https://rs.visa.com/pay-with-visa/security-and-assistance/protected-everywhere.html' target='_blank'><img src='/images/intesa-payment/visa_secure.jpg' /></a>
                        <a href='https://www.bancaintesa.rs' target='_blank'><img src='/images/intesa-payment/intesa.jpg' /></a>
                    </div>

                    <div className='intesa-payment-right'>
                        <img src='/images/intesa-payment/maestro.png' />
                        <img src='/images/intesa-payment/mastercard.png' />
                        <img className='dina-card' src='/images/intesa-payment/dina.jpg' />
                        <img src='/images/intesa-payment/visa.png' />
                        <img src='/images/intesa-payment/american-express-logo.png' />
                    </div>
                </div>
            }
                 
            <p>&copy; {getYear()} BrainIT. All rights reserved.</p>
        </div>
    );
}
