import React, { Fragment, useEffect, useState, useContext } from 'react';
import Service from '../components/Service';
import renderHTML from 'react-render-html';
import { StoreContext } from '../context/StoreContext';

export default function PrivacyPolicy(){
    const [privacyPolicyEn, setPrivacyPolicyEn] = useState('');
    const [privacyPolicySr, setPrivacyPolicySr] = useState('');
    const store = useContext(StoreContext);

    useEffect(()=>{
        getPrivacyPolicy();
    },[]);

    const getPrivacyPolicy=()=>{
        Service.get("/store/getCommonContent",{}, (res) => {
            if(res.success){
                setPrivacyPolicyEn(res.content[0].privacy_policy_en);
                setPrivacyPolicySr(res.content[0].privacy_policy_sr)
            }
        });
    }

    return(
        <div className="content">
            <div className="terms-of-use">
                {/* English privacy policy */}
                {store.store.lang === 'en' &&
                    <Fragment>
                        <h1 className="color-header">PRIVACY POLICY</h1>
                        {renderHTML('<div>'+privacyPolicyEn+'</div>')}
                    </Fragment>  
                }
                
                    
                {/* Serbian privacy policy */}
                {store.store.lang === 'sr' && 
                    <Fragment>
                        <h1 className="color-header">POLITIKA PRIVATNOSTI</h1>
                        {renderHTML('<div>'+privacyPolicySr+'</div>')}
                    </Fragment>
                }
            </div>
        </div>
    );
}