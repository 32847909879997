import React, { Component, Fragment } from 'react';
import Service from '../components/Service';
import T from '../components/Translate';

class Contact extends Component{

    constructor(props){
        super(props);
        this.state = {
            contactAddress: '',
            contactPhone: '',
            contactPhone2: '',
            contactEmail: '',
            contactFax: ''
        }

    }

    componentDidMount(){
        this.getContactInfo();
        setInterval(function(){
            this.forceUpdate();
        }.bind(this), 100);
    }

    getContactInfo=()=>{
        Service.get('/store/getContactInfo',{},(res)=>{
            this.setState({
                contactAddress: res.contact.contact_address,
                contactPhone: res.contact.contact_phone,
                contactPhone2: res.contact.contact_phone_2,
                contactEmail: res.contact.contact_email,
                contactFax: res.contact.contact_fax
            });
        })
    }

    render(){
        return(
            <div className="content">
                <h1 className="color-header"><T text="contactContact"/></h1>
                <div className="data">
                    <div className="data-left">
                        {this.state.contactAddress &&
                            <Fragment>
                                <h3><T text="contactAddress"/></h3>
                                <p>{this.state.contactAddress}</p>
                            </Fragment>
                        }

                        {this.state.contactPhone && this.state.contactPhone2 ?
                            <Fragment>
                                <h3><T text="contactPhone"/></h3>
                                <p>{this.state.contactPhone}, {this.state.contactPhone2}</p>
                            </Fragment>
                            : this.state.contactPhone || this.state.contactPhone2 ?
                            <Fragment>
                                <h3><T text="contactPhone"/></h3>
                                <p>{this.state.contactPhone ? this.state.contactPhone : this.state.contactPhone2}</p>
                            </Fragment>
                            : null
                        }

                        {this.state.contactEmail &&
                            <Fragment>
                                <h3><T text="contactEmail"/></h3>
                                <p>{this.state.contactEmail}</p>
                            </Fragment>
                        }

                        {this.state.contactFax &&
                            <Fragment>
                                <h3>Fax</h3>
                                <p>{this.state.contactFax}</p>
                            </Fragment>
                        }
                    </div>
                    {/* <div className="data-right">
                        <a href="#"><img src="https://www.coolbusinessideas.com/wp-content/uploads/2018/10/Google-Map-Now-Tells-You-Offers-From-Your-Favourite-Places-.jpg" /></a>
                    </div> */}
                </div>
        </div>
        );
    }

}

export default Contact;