import React, { useContext } from 'react';
import Lang from '../components/Lang';
import { StoreContext } from '../context/StoreContext';
import { Store } from '../components/Store';

let languageCodes = {
    en: 0,
    sr: 1
}

export default function T(props){
    const store = useContext(StoreContext);
    return <>{Lang[props.text][languageCodes[store.store.lang]]}</>;
}

export function t(key, debug=false) {
    return Lang[key][languageCodes[Store.lang]];
}

