import React, { Fragment, useEffect, useState, useContext } from 'react';
import Service from '../components/Service';
import renderHTML from 'react-render-html';
import { StoreContext } from '../context/StoreContext';

export default function TermsOfUse(){
    const [termsOfUseEn, setTermsOfUseEn] = useState('');
    const [termsOfUseSr, setTermsOfUseSr] = useState('');
    const store = useContext(StoreContext);

    useEffect(()=>{
        getTermsOfUse();
    },[])

    const getTermsOfUse=()=>{
        Service.get("/store/getCommonContent",{}, (res) => {
            if(res.success){
                setTermsOfUseEn(res.content[0].terms_of_use_en);
                setTermsOfUseSr(res.content[0].terms_of_use_sr);
            }
        });
    }

    return(
        <div className="content">
            <div className="terms-of-use">
                {/* English terms of use */}
                {store.store.lang === 'en' &&
                    <Fragment>
                        <h1 className="color-header">TERMS OF USE</h1>
                        {renderHTML('<div>'+termsOfUseEn+'</div>')}
                    </Fragment>  
                }
                
                    
                {/* Serbian terms of use */}
                {store.store.lang === 'sr' && 
                    <Fragment>
                        <h1 className="color-header">USLOVI KORIŠĆENJA</h1>
                        {renderHTML('<div>'+termsOfUseSr+'</div>')}
                    </Fragment>
                }
            </div>
        </div>
    )
}