import React, { Component, Fragment } from 'react';
import {Cart} from '../components/Cart';
import Service from '../components/Service';
import T from '../components/Translate';
import { StoreContext } from '../context/StoreContext';

class Success extends Component{
    static contextType = StoreContext;
    constructor(props){
        super(props);

        // remove all cart items after successful purchase
        // Cart.clearCart();
        this.state = {
            order: {},
            products: [],
            transactionResponse: {}
        }

    }

    getOrderInfo(){
        Service.get('/orders/getOrderInfo',{id: this.props.match.params.oid},(res)=>{
            this.setState({order: res.order, products: res.order.products},()=>{
                if(this.state.order.status){
                    Cart.clearCart();
                }
                if(this.state.order.payment_method === 'cc'){
                    this.setState({transactionResponse: this.state.order.transaction_response})
                }
            });
        })
    }

    componentDidMount(){
        this.getOrderInfo();
    }
    

    render(){
        return(
            <div className="content">
                {this.state.order.payment_method === 'cc' && this.state.order.status === 1 &&
                    <h1>Uspešno ste izvršili plaćanje – račun Vaše platne kartice je zadužen.</h1>
                }

                {this.state.order.payment_method === 'cc' && this.state.order.status === 0 &&
                    <>
                        <h1>Plaćanje neuspešno – račun Vaše platne kartice nije zadužen.</h1>
                        <p>Plaćanje nije uspešno, vaš račun nije zadužen. Najčešći uzrok je pogrešno unet broj kartice, 
                        datum isteka ili sigurnosni kod. Pokušajte ponovo, a u slučaju uzastopnih grešaka pozovite 
                        vašu banku</p>
                    </>
                }

                {(this.state.order.payment_method === 'pp' || this.state.order.payment_method === 'up') &&
                    <h1><T text="success_message"/></h1>
                }
            
                {this.state.order.payment_method === 'up' &&
                    <div className="success-up">
                        <img src="/images/uplatnica.jpg"/>
                        <p className="success-up-name">{this.state.order.name}</p>
                        <p className="success-up-address">{this.state.order.billing_address}, {this.state.order.zip_code} {this.state.order.city}</p>
                        <p className="success-up-pop">Uplata po predračunu br. {this.state.order.id}</p>
                        <p className="success-up-recipient">{this.context.store.company_name}, {this.context.store.contact_address}, {this.context.store.contact_municipality}</p>

                        <p className="success-up-currency">rsd</p>
                        <p className="success-up-total">{this.state.order.total}</p>
                        <p className="success-up-paccount">{this.context.store.company_account_number}</p>
                        <p className="success-up-model">97</p>
                        <p className="success-up-reference-num">{this.state.order.reference_number}</p>
                        <p className="success-up-info"><i>*Nakon registrovanja prijema Vaše uplate i računovodstvene obrade roba ce biti poslata na adresu navedenu prilikom porudžbine.</i></p>
                    </div>
                }

                {(this.state.order.payment_method === 'cc' && this.context.store.payment_cc === 1 && this.context.store.payment_type === 1) &&
                    <div className='chipcard-info'>
                        <hr />
                        <div className='chipcard-transaction-info'>
                            <h2>Podaci o transakciji</h2>
                            {this.state.transactionResponse.pgTranApprCode &&
                                <p>Autorizacioni kod: {this.state.transactionResponse.pgTranApprCode}</p>
                            }
                            <p>Status transakcije: {this.state.transactionResponse.responseMsg}</p>
                            <p>Kod statusa transakcije: {this.state.transactionResponse.responseCode}</p>
                            <p>Datum transakcije: {this.state.transactionResponse.pgTranDate}</p>
                            <p>Iznos transakcije: {Service.price(parseInt(this.state.transactionResponse.amount))} RSD</p>
                            <p>Referentni ID transakcije: {this.state.transactionResponse.pgTranRefId}</p>
                        </div>
                        
                        <div className='chipcard-merchant-info'>
                            <h2>Podaci o trgovcu</h2>
                            <p>{this.context.store.company_name}</p>
                            <p>{this.context.store.contact_address}</p>
                            <p>PIB: {this.context.store.company_pib}</p>
                        </div>
                        <hr />
                    </div>
                }   

                {(this.state.order.payment_method === 'cc' && this.context.store.payment_cc === 1 && this.context.store.payment_type === 2) &&
                    <div className='chipcard-info'>
                        <hr />
                        <div className='chipcard-transaction-info'>
                            <h2>Podaci o transakciji</h2>
                            <p>Broj narudžbine: {this.state.transactionResponse.ReturnOid}</p>
                            {this.state.transactionResponse.AuthCode &&
                                <p>Autorizacioni kod: {this.state.transactionResponse.AuthCode}</p>
                            }
                            <p>Status transakcije: {this.state.transactionResponse.Response}</p>
                            <p>Kod statusa transakcije: {this.state.transactionResponse.ProcReturnCode}</p>
                            <p>Datum transakcije: {this.state.transactionResponse.EXTRA_TRXDATE}</p>
                            <p>Statusni kod 3D transakcije: {this.state.transactionResponse.mdStatus}</p>
                        </div>

                        <div className='chipcard-merchant-info'>
                            <h2>Podaci o trgovcu</h2>
                            <p>{this.context.store.company_name}</p>
                            <p>{this.context.store.contact_address}</p>
                            <p>PIB: {this.context.store.company_pib}</p>
                        </div>
                        <hr />
                    </div>
                }   


                <p>
                    <T text="success_order_number"/> {this.state.order.id}
                </p>

                <h3>{this.state.order.name}</h3>
                <p>{this.state.order.email}<br/>
                {this.state.order.phone}</p>
                <p>
                    {this.state.order.billing_address}<br />
                    {this.state.order.zip_code} {this.state.order.city} <br />
                    {this.state.order.municipality}<br />
                    {this.state.order.country}<br />
                    {/* {this.state.order.billing_address}<br /> */}
                </p>

                <h2><T text="success_articles"/></h2>
                <table className="success-table">
                    <tr>
                        <td><T text="title"/></td>
                        <td><T text="price"/></td>
                        <td><T text="quantity"/></td>
                        <td><T text="cartSubtotal"/></td>
                    </tr>
                
                    {/* <div> */}
                        {this.state.products.map(function(item, index){
                            return(
                                <tr>
                                    <td>{item.name}</td>
                                    <td>{Service.price(item.unit_price)} </td>
                                    <td>{item.quantity} <T text="success_pcs"/></td>
                                    <td>{Service.price(item.price)}</td>
                            {/* <p><b>{item.title}</b>, {item.unit_price} RSD x {item.quantity} KOM. ukupno: {item.price} RSD</p> */}
                                </tr>
                            )
                        },this)
                        }

                    
                    {/* </div> */}
                </table>
                <p><T text="cartShipping"/>: {Service.price(this.state.order.shipping_price)} </p>
                <p><T text="cartTotal"/>: {Service.price(this.state.order.total)} </p>
     
            </div>
        );
    }

}

export default Success;