import React, { Component, Fragment } from 'react';
import Service from '../components/Service';
import {Cart} from '../components/Cart';
import {Link} from 'react-router-dom';
import Categories from '../components/Categories'
import {t} from '../components/Translate';
import T from '../components/Translate';
import { StoreContext } from '../context/StoreContext';
import ReactPaginate from 'react-paginate';
import {withRouter} from 'react-router-dom';
const queryString = require('query-string');

class Shop extends Component {
    static contextType = StoreContext;
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            filter: '',
            category: '',
            perPage: 9,
            page: 1,
            numberOfProducts: 0
        }
    }

    getParams = queryString.parse(this.props.location.search);
    page;
    filter;
    params = {};

    componentDidMount(){
        this.getProducts();

        this.updatePage();
    }

    componentDidUpdate(prevProps){
        if(prevProps.match.params !== this.props.match.params){
            this.getProducts();
            this.getCategory();
            // console.log('aaaa', prevProps, this.props);
        }

        if (this.props.location !== prevProps.location) {
            //console.log('route change');
            this.updatePage();
        }
    }

    componentWillReceiveProps(newProps){
        let getParams = queryString.parse(newProps.location.search);
        if(getParams.page){
            this.page = parseInt(getParams.page);
        }else{
            this.page = 1;
        }
    }

    getProducts=()=>{
        if(this.props.match.params.filter === 'category'){
            // get products by category
           Service.get("/products/get",{
               categoryId: this.props.match.params.a, 
               filter: this.state.filter,
               page: this.page,
               perPage: this.state.perPage
            }, (res) => {
                this.setState({products: res.data.products, numberOfProducts: res.data.total_products})
            }); 
            return;
        }

        // no cat 
        Service.get("/products/get",{
            filter: this.state.filter,
            page: this.page,
            perPage: this.state.perPage
        }, (res) => {
            this.setState({products: res.data.products, numberOfProducts: res.data.total_products})
        });
    }

    getCategory=()=>{
        Service.get("/categories/getCategory",{categoryId: this.props.match.params.a}, (res) => {
            if(res.category[0]){
                this.setState({category: res.category[0].name})
            }
        });
    }

    addToCart=(event)=>{
        let productId = event.target.getAttribute('data-product-id');
        if(productId){
            Cart.addToCart(productId);
        }
    }

    handleFilter=(event)=>{
        this.setState({filter: event.target.value},()=>{
            this.getProducts();
        })
    }

    getNumberOfPages=()=>{
        return Math.ceil(this.state.numberOfProducts/this.state.perPage);
    }
    
    changePage = (page) => {
        let link;

        if(page.selected){
            link = `${this.props.location.pathname}?page=${page.selected + 1}`;
        }else{
            link = this.props.location.pathname;
        }

        this.props.history.push(link);
    }

    updatePage=()=>{
        let getParams = queryString.parse(this.props.location.search);
        if(getParams.page){
            this.page = parseInt(getParams.page);
        }else{
            this.page = 1;
        }

        setTimeout(()=>{
            this.getProducts();
        },100);
    }

    render() {
        return(
            <div className="content shop">
                <Categories />
    		    <div className="home-products shop-products">
                    {/* <h1 className="color-header">{ta("shopShop")}</h1> */}
                    {this.state.category && <h2 className="color-header">{this.state.category}</h2>}
                    <div className='search-container'><input placeholder={t("search")} type="text" value={this.state.filter} onChange={this.handleFilter} /></div>
                    <ul className="shop-products-container">
                        {this.state.products.map(function(item, index){
                            return(
                                <li className="home-product">
                                    {/* <img src={item.image} /> */}
                                    <Link to={`/product/${item.id}/${item.slug}`}>
                                        {/* <div className="home-product-image" style={{backgroundImage: `url(${item.image})`}}></div> */}
                                        <img className="product-image-home" src={item.image} />
                                    </Link>
                                    {/* <h3>{item.name}</h3> */}
                                    <div className="home-product-info">
                                        <p className="product-title"><strong>{item.name}</strong></p>
                                        <p className="product-description">{item.short_description}</p>
                                        <div className="home-product-price">{Service.price(item.price)}</div>
                                    </div>
                                
                                    {this.context.store.type === 1 &&
                                        <Fragment>
                                            {item.quantity_status === 1 && item.quantity > 0 ? 
                                                <Link to="/contact"><div className="shop-button"><T text="contactUsButton"/></div></Link>
                                                : item.quantity_status === 1 && item.quantity === 0 ?
                                                    <div className="shop-button"><T text="soldOutButton"/></div>
                                                : <div className="shop-button" onClick={this.addToCart} data-product-id={item.id}><T text="addToCartButton"/><i className="fas fa-cart-plus"></i></div>
                                            }
                                        </Fragment>
                                    }
                            
                                    {this.context.store.type === 2 &&
                                        <Fragment>
                                            <Link to={`/product/${item.id}/${item.slug}`}>
                                                <div className="shop-button"><T text="readMoreButton"/></div>
                                            </Link>
                                        </Fragment>
                                    }
                                </li>
                            )
                        },this)}
                    </ul>

                    <ReactPaginate
                        pageCount={this.getNumberOfPages()}
                        pageRangeDisplayed={window.innerWidth > 720 ? 5 : 1}
                        marginPagesDisplayed={window.innerWidth > 720 ? 3 : 1}
                        previousLabel={'<'}
                        nextLabel={'>'}
                        forcePage={this.page - 1}
                        containerClassName={'pager-container'}
                        activeClassName={'pager-selected'}
                        onPageChange={this.changePage}
                    />
                </div>
            </div>
        );
    }

}

export default withRouter(props => <Shop {...props}/>);
