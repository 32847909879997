import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import TopProducts from '../components/TopProducts';
import {t} from '../components/Translate';
import T from '../components/Translate';
import renderHTML from 'react-render-html';

class NoMatch extends Component {

    // constructor(props){
    //     super(props);
    //     this.state = {
    //         oldPath: ''
    //     }
    // }

    componentDidMount(){
        setInterval(function(){
            this.forceUpdate();
        }.bind(this), 200);
    }
   
    render(){
        return(
            <div>
                <div className="left-content">
                    <TopProducts />
                </div>
                <div className="right-content">
                    <h1><T text="noMatchTitle"/></h1>
                    <p>{renderHTML('<div>'+t("noMatchSubTitle")+'</div>')}</p>
                </div>
            </div>
        );
    }

}

export default NoMatch;