import React, { Component } from 'react';
import Service from '../components/Service';
import {Auth} from '../components/Auth';
import duix from 'duix';
import T from '../components/Translate';

var validator = require("email-validator");
// !validator.validate(this.state.email)

class Register extends Component{

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            nameError: 'none',
            email: '',
            registerEmail: '',
            emailError: 'none',
            registerEmailError: 'none',
            password: '',
            passwordError: 'none',
            passwordConfirm: '',
            passwordConfirmError: 'none',
            registerPassword: '',
            registerPasswordError: 'none',
            registerNameError: 'none',
            loginEmail: '',
            loginEmailError: 'none',
            loginPassword: '',
            loginPasswordError: 'none',
            subscribe: true,
            toggleResetPassword: false,
            resetPasswordEmail: '',
            loginErrorMessage: '',
            redirect: null
        }
    }

    componentDidMount(){
        this.setState({redirect: this.props.match.params.message, redirectValue: this.props.match.params.value},()=>{
            console.log('rdr', this.state.redirect);
        })

        setInterval(function(){
            this.forceUpdate();
        }.bind(this), 100);
    }

    // register
    updateName = (event) => {
        this.setState({name: event.target.value});
    }
    updateEmail = (event) => {
        this.setState({email: event.target.value});
    }
    updatePassword = (event) => {
        this.setState({password: event.target.value});
    }
    updatePasswordConfirm = (event) => {
        this.setState({passwordConfirm: event.target.value});
    }
    updateSubscribe = (event) => {
        this.setState({subscribe: event.target.checked});
    }

    register = () => {
        if(!this.checkRegisterInputs()){
            alert(<T text="checkRegisterInputs"/>);
            return;
        }
        Service.register(this.state.registerEmail, this.state.registerPassword, this.state.registerName, (res)=>{
            //duix.set('', 1);
            if(res.success){
                duix.set('registration', 1);
                this.props.history.push('/message/registered');
            }else{
                if(res.info === 'email_exists'){
                    alert(<T text="registerEmailExistError"/>);
                }else{
                    alert(<T text="registerRegistrationError"/>);
                }
            }
        });
    }

    // login
    updateLoginEmail = (event) => {
        this.setState({loginEmail: event.target.value});
    }
    updateLoginPassword = (event) => {
        this.setState({loginPassword: event.target.value});
    }

    checkLoginInputs(){
        let check = true;
        if(!validator.validate(this.state.email)){
            this.setState({emailError: 'block'});
            check = false;
        }else{
            this.setState({emailError: 'none'});
        }
        if(!this.state.password){
            this.setState({passwordError: 'block'});
            check = false;
        }else{
            this.setState({passwordError: 'none'});
        }


        if(check){
            return true;
        }

    }

    checkRegisterInputs(){
        let check = true;

        // if(!this.state.name){
        //     this.setState({nameError: 'block'});
        //     check = false;
        // }else{
        //     this.setState({nameError: 'none'});
        // }
        if(!this.state.registerName){
            this.setState({registerNameError: 'block'});
            check = false;
        }else{
            this.setState({registerNameError: 'none'});
        }


        if(!validator.validate(this.state.registerEmail)){
            this.setState({emailError: 'block'});
            check = false;
        }else{
            this.setState({emailError: 'none'});
        }

        if(!this.state.registerPassword){
            this.setState({passwordError: 'block'});
            check = false;
        }else{
            this.setState({passwordError: 'none'});
        }

        if(this.state.passwordConfirm !== this.state.registerPassword){
            this.setState({passwordConfirmError: 'block'});
            check = false;
        }else{
            this.setState({passwordConfirmError: 'none'});
        }


        if(check){
            return true;
        }

    }

    login = () => {
        if(!this.checkLoginInputs()){
            return;
        }

        let redirect = this.state.redirect;
        let redirectValue = this.state.redirectValue;
        //console.log('rdr2', redirect);

        this.setState({loginErrorMessage: ''});
        Auth.email = this.state.email;
        Auth.authenticate(this.state.password, () => {
            // redirect to welcome page home page in this case
            //this.props.updateRegistration();
            //duix.set('', 1);
            //console.log('message', redirect);
            duix.set('registration', 1);
            if(redirect == 'za-stampu'){
                this.props.history.push('/za-stampu/'+redirectValue);
            }else{
                this.props.history.push('/');
            }

        },
        (message)=>{ //login failure
            this.setState({loginErrorMessage: message});
        })
    }

    signout = () => {
        // Auth.logout((result)=>{
        //     // redirect to welcome page home page in this case
        //     duix.set('wishlistItems', 1);
        //     this.props.updateRegistration();
        //     this.props.history.push('/');
        // });
    }

    resetPassword=()=>{
        this.setState({toggleResetPassword: !this.state.toggleResetPassword});
    }

    updateResetPasswordEmail=(event)=>{
        this.setState({resetPasswordEmail: event.target.value});
    }
    updateRegisterName = (event) => {
        this.setState({registerName: event.target.value});
    }
    
    updateRegisterEmail = (event) => {
        this.setState({registerEmail: event.target.value});
    }
    
    updateRegisterPassword = (event) => {
        this.setState({registerPassword: event.target.value});
    }

    submitResetPassword=()=>{
        Service.get('/users/resetPassword',{email: this.state.resetPasswordEmail},(res)=>{
            if(res.success){
                this.props.history.push('/message/passwordReset');
            }
        })
    }

    render(){
        return(
            <div className="content">
                <div className="form sign-up">
                
                    <div className="registration-message">
                        {this.props.match.params.message == 'success' &&
                            <h3><T text="successActivationAccount"/></h3>
                        }

                        {this.props.match.params.message == 'successPasswordUpdate' &&
                            <h3><T text="successUpdatePassword"/></h3>
                        }
                    </div>
        
                    <div>
                        <h2><T text="signInBtn"/>:</h2>
                        <label><T text="registerEmail"/></label>
                        <input type="text" value={this.state.email} onChange={this.updateEmail} />
                        <p className="error" style={{display: this.state.emailError}}><T text="emailErrorMessage"/></p>
                        
                        <label><T text="registerPassword"/></label>
                        <input type="password" value={this.state.password} onChange={this.updatePassword} />
                        <p className="error" style={{display: this.state.passwordError}}><T text="registerPasswordErrorMessage"/></p>
                        
                        <label className="error">{this.state.loginErrorMessage}</label>
                        <div className="forgot-password-container"><T text="registerForgotPassword"/> <button onClick={this.resetPassword}><T text="registerClickHereBtn"/></button></div>
                        
                        {this.state.toggleResetPassword === true &&
                            <div>
                                <p><T text="registerResetPasswordAlert"/></p>
                                <input type="text" value={this.state.resetPasswordEmail} onChange={this.updateResetPasswordEmail} />
                                <button type="button" className="forgot-password" onClick={this.submitResetPassword}><T text="submit"/></button>
                            </div>
                        }
                        <div className="login-button-container">
                            <div className="button action-button" onClick={this.login}><T text="signInBtn"/></div>
                        </div>
                    </div>
                </div>
                <div className="form sign-up">
                    <h2><T text="signUpBtn"/>:</h2>
                    <div>
                        <p><T text="registerInfoMsg"/></p>
                        <label><T text="registerName"/></label>
                        <input type="text" value={this.state.registerName} onChange={this.updateRegisterName} />
                        <p className="error" style={{display: this.state.registerNameError}}><T text="registerNameErrorMessage"/></p>
                        
                        <label><T text="registerEmail"/></label>
                        <input type="text" value={this.state.registerEmail} onChange={this.updateRegisterEmail} />
                        <p className="error" style={{display: this.state.registerEmailError}}><T text="registerEmailErrorMessage"/></p>
                        
                        <label><T text="registerPassword"/></label>
                        <input type="password" value={this.state.registerPassword} onChange={this.updateRegisterPassword} />
                        <p className="error" style={{display: this.state.registerPasswordError}}><T text="registerPasswordErrorMessage"/></p>
                        
                        <label><T text="confirmPassword"/></label>
                        <input type="password" value={this.state.passwordConfirm} onChange={this.updatePasswordConfirm} />
                        <p className="error" style={{display: this.state.passwordConfirmError}}><T text="registerRepeatPasswordErrorMessage"/></p>

                        <div className="register-button-container">
                            <div className="button action-button" onClick={this.register}><T text="signUpBtn"/></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default Register;