import React, { Component }  from 'react';
import Service from '../components/Service';
import duix from 'duix';
import {NotificationManager} from 'react-notifications';
import T from '../components/Translate';


export const Cart = {

    cartArray: [],
    cartTotal: 0,
    cartShipping: 0,

    initCart(){
        if(localStorage.getItem('brntshp'+window.location.hostname.split('.')[0]+'-cartItems')){

            this.cartArray = JSON.parse(localStorage.getItem('brntshp'+window.location.hostname.split('.')[0]+'-cartItems'));
        }
    },

    addToCart(product){

        for(let p in this.cartArray){
            //ukoliko se id poklapa sa isbn koji je prosledjen
            if(parseInt(this.cartArray[p].id) === parseInt(product)){
                this.cartArray[p].quantity ++;
                this.updateCart();
                localStorage.setItem('brntshp'+window.location.hostname.split('.')[0]+'-cartItems', JSON.stringify(this.cartArray));
                //duix.set('modal', 'Proizvod je dodat u korpu.');
                
                NotificationManager.success(<T text="successMessageAddToCart"/>, <T text="cart"/>)

                return;
            }else{
                
            }
           
        }
        //dodavanje u niz

        this.cartArray.push({id:product, quantity:1});
        this.updateCart();
        localStorage.setItem('brntshp'+window.location.hostname.split('.')[0]+'-cartItems', JSON.stringify(this.cartArray));
        NotificationManager.success(<T text="successMessageAddToCart"/>, <T text="cart"/>)
        // duix.set('modal', 'Proizvod je dodat u korpu.');
        //duix.set('cartItems', this.cartArray);
        duix.set('cartItems', this.cartArray);


    },


    clearCart(){
        localStorage.removeItem('brntshp'+window.location.hostname.split('.')[0]+'-cartItems');
        this.cartArray = [];
        this.updateCart();
        //duix.set('cartItems', this.cartArray);
    },

    getTotal(){
        return this.cartTotal;
    },

    getCartShipping(){
        return this.cartShipping;
    },

    getCart(){
        return this.cartArray;
    },
    getCartElements(){
        return this.cartArray;
    },
    getCartProductsQuantities(){
        let r = [];
        for(var p in this.cartArray){
            r.push({id: this.cartArray[p].id, quantity: this.cartArray[p].quantity});
        }
        return r;
    },
    getCartProductsNumber(){
        return this.cartArray.length;
    },
    removeIfLastCopy(product){
        this.cartArray.splice(this.cartArray.indexOf(product),1);
        this.updateCart();
    },
    removeFromCart(id){
        for(let p in this.cartArray){
            if(parseInt(this.cartArray[p].id) == id){
                this.cartArray.splice(p,1);
            }
        }
        localStorage.setItem('brntshp'+window.location.hostname.split('.')[0]+'-cartItems', JSON.stringify(this.cartArray));
        this.updateCart();

    },

    removeOneFromCart(product){
        for(let p in this.cartArray){
            if(parseInt(this.cartArray[p].id) === parseInt(product)){
                //isto kao i u funkciji addToCart samo sto smanjuje
                this.cartArray[p].quantity --;
                if(this.cartArray[p].quantity < 1){
                    //ukoliko value dodje na 0, brise se ceo element na p poziciji
                    this.removeIfLastCopy(this.cartArray[p]);
                }
                localStorage.setItem('brntshp'+window.location.hostname.split('.')[0]+'-cartItems', JSON.stringify(this.cartArray));
                this.updateCart();

                return;
            }
           
        }

    },

    reduceCart(missing){
        this.cartArray.map((item)=>{
            missing.map((product)=>{
                if(item.id == product.id){
                    item.quantity -= product.missing;

                    if(item.quantity < 1){
                        this.removeFromCart(item.id);
                    }
                }
            })
        })

        localStorage.setItem('brntshp'+window.location.hostname.split('.')[0]+'-cartItems', JSON.stringify(this.cartArray));
        this.updateCart();

        return;
    },

    updateCart(){
       // Service.getCart({cartProducts: this.getCartProductsQuantities()},(result)=>{
        //     this.cartTotal = result.data.cartTotal;
        //     this.cartShipping = result.data.shippingPrice;
        //     duix.set('cartItems', this.cartArray);
        // });
        //this.cartTotal = Math.round(Math.random() * 1000);
        //this.cartShipping = 180;
        duix.set('cartItems', this.cartArray);
    }
    
 
}