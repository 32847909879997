import React, { Component } from 'react';
import TopProducts from '../components/TopProducts';
import NewsSidebar from '../components/NewsSidebar';
import Service from '../components/Service';



class Howto extends Component {


   

    
    render(){
        return(
            <div>
                <div className="left-content">
                    <TopProducts />
                    <NewsSidebar />
                </div>
                <div className="right-content">
                   
                </div>
            </div>
        );
    }

}

export default Howto;