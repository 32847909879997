import React, { Component, Fragment } from 'react';
import Selector from '../components/Selector';


class Shipping extends Component{

    constructor(props){
        super(props);
        this.state = {
            address: '',
            city: '',
            country: 'Srbija',
            email: '',
            municipality: '',
            name: '',
            telephone: '',
            zipCode: '',
            dNameError: 'none',
            dStreetError: 'none',
            dCityError: 'none',
            dStreetNumberError: 'none',
            dTelephoneError: 'none',
            dShippingStreet:'',
            dShippingStreetNumber:'',
            dShippingTown:'',
            dShippingCity:'',
            dShippingZipCode:'',
            dShippingTownLabel:'',
            townCheckbox: false
        }
    }

    componentWillReceiveProps(props){
        this.setState({
            dNameError: props.dNameError,
            dStreetError: props.dStreetError,
            dCityError: props.dCityError,
            dStreetNumberError: props.dStreetNumberError,
            dTelephoneError: props.dTelephoneError,
        })
    }


    handleInput=(event)=>{
        this.setState({[event.target.id]: event.target.value},
            ()=>{this.props.updateShippingData(this.state)}
        );
    }

    handleTownCheckbox = event => {
        console.log(event.target.checked)
        this.setState({ townCheckbox: event.target.checked })
    }
    // handlePhoneNumber=(event)=>{
    //     let p = event.target.value.replace(/[^0-9.]/g, '');
    //     this.setState({telephone: p},()=>{
    //         console.log("Telephone", this.state.telephone)
    //         this.props.updateShippingData(this.state)
    //     })
    // }

    // formatTelephone(phone){
    //     let pn = phone.replace(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/, '($1) $2-$3');
    //     return pn;
    // }

    handlePhoneNumber=(event)=>{
    if(event.target.value.length < 13){
        let p = event.target.value.replace(/[^0-9.]/g, '');
        this.setState({telephone: this.formatTelephone(p)},()=>{
            console.log("Telephone", this.state.telephone)
            this.props.updateShippingData(this.state)
        })
    }
}

formatTelephone(phone){

    let p = phone.toString();
    let rest = p.substring(6);
    let second = p.substring(3,6);
    let first = p.substring(0,3);
    
    
    console.log('tel', second, rest, phone)
    if(p.length < 4){
        return first;
    }else if(p.length < 7){
        return first + '-' + second;
    }else{
        return first + '-' + second + '-' + rest;
    }
    // let pn = phone.replace(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/, '($1) $2-$3');
    // return pn;
}


    setAddress=(data)=>{
        this.setState({dShippingStreet: data[0].value.replace(/[^0-9a-zA-Z.ŠĐŽĆČŽšđčćž\s]/g,'')},()=>{
            // console.log("shipping dStreet", this.state.dShippingStreet);
            this.props.updateShippingData(this.state);
        })
    }

    setStreetNumber=(event)=>{
        this.setState({dShippingStreetNumber: event.target.value.replace(/[^0-9a-zA-Z\/ ŠĐŽĆČŽšđčćž\s]/g,'')},()=>{
            // console.log(this.state.dShippingStreetNumber);
            this.props.updateShippingData(this.state);
        })
    }

    setTown=(data)=>{
        // console.log("Shipping town",data);
        this.setState({
            dShippingTown: data[0].town_name,
            dShippingCity: data[0].city_name,
            dShippingZipCode: data[0].ptt_no,
            dShippingTownLabel: data[0].label
        },()=>{
            this.props.updateShippingData(this.state);
        })
    }

    setName=(event)=>{
        this.setState({name: event.target.value.replace(/[^A-Za-zŠĐŽĆČŽšđčćž\s!?]/g,'')},()=>{
            this.props.updateShippingData(this.state);
        })
    }

    render(){
        return(
            <div>
                <h3>Adresa isporuke:</h3>
                <div className="cart-inputs">
                    <h3>Ime i prezime isporuke</h3>
                    <input type="text" id='name' value={this.state.name} onChange={this.setName} />
                    <p className="error" style={{display: this.state.dNameError}}>* morate da unesete ime i prezime.</p>
                    
                    <div className="cart-street-box">
                        <h3>Adresa</h3>
                        {/* <Selector route='/orders/getStreets' filters={{competition: this.state.competition, brands: this.state.selectedBrands, subBrands: this.state.selectedSubBrands}} placeholder="Adresa..."/> */}
                        {/* <input type="text" id='address' value={this.state.address} onChange={this.handleInput} /> */}
                        <Selector onChange={this.setAddress} route='/orders/getStreets' inputValue={this.state.dShippingStreet} forced={true} type='single'/>
                        <p className="error" style={{display: this.state.dStreetError}}>* morate da unesete adresu.</p>
                    </div>
                    <div className="cart-streetNum-box">
                        <h3>Broj</h3>
                        <input type="text" id='dStreetNumber' value={this.state.dShippingStreetNumber} onChange={this.setStreetNumber} />
                        <p className="error" style={{display: this.state.dStreetNumberError}}>* morate da unesete broj ulice.</p> 
                    </div>

                    {!this.state.townCheckbox &&
                        <Fragment>
                            <h3>Grad</h3>
                            <Selector onChange={this.setTown} route='/orders/getTowns' filters={{street: this.state.dShippingStreet}} inputValue={this.state.dShippingTownLabel} type="single"/>
                            {/* <input type="text" id='city' value={this.state.city} onChange={this.handleInput} /> */}
                        </Fragment>
                    }

                    {this.state.townCheckbox &&
                        <Fragment>
                            <h3>Drugo mesto</h3>
                            <Selector onChange={this.setTown} route='/orders/getAllTowns' inputValue={this.state.dShippingTownLabel} type="single"/>
                            {/* <input type="text" id='city' value={this.state.city} onChange={this.handleInput} /> */}
                        </Fragment>
                    }
                    <div className="town-checkbox">
                        <input id='stcb' type="checkbox" style={{display: 'inline-block', verticalAlign: 'middle', width:'5%'}} value={this.state.townCheckbox} onChange={this.handleTownCheckbox} />
                        <label htmlFor="stcb" style={{display: 'inline-block', verticalAlign: 'middle'}}> Izaberite drugo mesto</label>
                    </div>
                    <p className="error" style={{display: this.state.dCityError}}>* morate da odaberete drugo mesto.</p>

                </div>
                <div className="cart-inputs">
                    {/* <h3>Poštanski broj isporuke</h3>
                    <input type="text" id='zipCode' value={this.state.zipCode} onChange={this.handleInput} />
                    <p className="error" style={{display: this.state.zipCodeError}}>* morate da unesete poštanski broj.</p> */}
                    <h3>Država isporuke</h3>
                    {/* <input type="text" id='country' value={this.state.country} onChange={this.handleInput} /> */}
                    <input type="text" id='country' value='Srbija' readOnly/>
                    <h3>Broj telefona isporuke</h3>
                    <input type="text" id='telephone' value={this.state.telephone} onChange={this.handlePhoneNumber} maxLength="20"/>
                    <p className="error" style={{display: this.state.dTelephoneError}}>* morate da unesete vaš broj telefona.</p>
                    <h3>Email isporuke</h3>
                    <input type="text" id='email' value={this.state.email} onChange={this.handleInput} />
                    {/* <p className="error" style={{display: this.state.dEmailError}}>* morate da unesete vaš email.</p> */}
                </div>
            </div>
        );
    }

}

export default Shipping;