import React, { Component} from 'react';
import {BrowserRouter as Router, Route, Link, Read} from 'react-router-dom';
import Service from './Service';
import T from '../components/Translate';

function handleCategories(item){
    console.log(item) // ovo mi radi
    return(
        <fragment>
            <li>{item.name}</li> {/* ovo prikaze */}
            {item.categories &&
                <fragment>
                <ul>
                {item.categories.map(sub => {
                    console.log(item) // ovo mi radi
                    return(handleCategories(sub)) //ovo ne renderuje nista?
                })}
                </ul>
                </fragment>
            }
        </fragment>
    )
}

class Categories extends Component {

    constructor(props){
        super(props);
        this.state = {
            numberOfProducts: 0,
            categories: [],
            visible: false
        }

    }
 
    componentDidMount(){
        this.getAllCategories();

    }

    getAllCategories = () => {
      Service.get('/categories/get',{},(res)=>{
          if(res.success){
            this.setState({categories: res.categories},()=>{
            });
          }
      });

    }

    toggleVisibility=()=>{
        let p = !this.state.visible;
        this.setState({visible: p});
    }

    handleCategories=(item, from)=>{
        console.log(item) // ovo mi radi
        return(
            <fragment>
                <li>
                    {item.categories && item.categories.length > 0 &&
                        <span className="category-arrow" onClick={()=>{this.setParentOpen(item)}}>{item.open ? '▾' : '▸'}</span>
                    }
                   <span className="category-title" onClick={()=>{this.selectCategory(item.id)}}>{item.name}</span>
                    {/* {item.name} */}
                </li>
                 {/* ovo prikaze */}
                {item.categories && item.open &&
                    <ul className="parent-subcategories">
                    {item.categories.map(sub => {
                        console.log(item) // ovo mi radi
                        return(this.handleCategories(sub)) //ovo ne renderuje nista?
                    })}
                    </ul>
                }
                
            </fragment>
        )
    }

    setParentOpen=(item)=>{
        if(item.categories){
            item.open = !item.open;
            handleCategories(item)
            this.forceUpdate();
        }
    }

    selectCategory=(id)=>{
        this.setState({visible: false},()=>{
            window.location.href= `/shop/category/${id}`;
        })
    }

    render() {
        return (
            <div className="categories-container">
                <h3 onClick={this.toggleVisibility}><T text="categories"/></h3>
                {(this.state.visible || window.screen.width > 600) &&
                <ul>
                    <Link to={`/shop`}><li onClick={()=>{this.setState({visible: false})}}><T text="all_products"/></li></Link>
                    {this.state.categories.map(function(item, index){
                        if(item.categories){
                            return(this.handleCategories(item))
                        }else{
                            return(
                                <li onClick={()=>{this.selectCategory(item.id)}}>{item.name}</li>
                            )
                        }
                        
                    },this)
                    }
                </ul>
                }
            </div>
        );
    }

}

export default Categories;
