import React, { useEffect, useState } from 'react';
import Service from '../components/Service';
export const StoreContext = React.createContext();
export const StoreProvider = props => {
    const[store, setStore] = useState({})
    useEffect(()=>{
        Service.get('/store/get', {}, (res)=>{setStore(res.data)})
    }, []);
    const contextValue = {
        store
    };

    return (
        <StoreContext.Provider value={contextValue}>{props.children}</StoreContext.Provider>
    );
};