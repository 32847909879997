import React, { Component } from 'react';
import Service from '../components/Service';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
class Selector extends Component {

    constructor(props){
        super(props);
        this.state = {
            results: [],
            selectedData: [],
            inputValue: '',
            options: [],
            resultsVisible: 'none',
            applyFilter: true,
            loaderVisible: false

        }

    }

    componentDidMount(){
        if( this.props.type || this.props.type === 'single'){
            // this.setState({multi: false, autoClose: true});
        }
    }

    componentWillReceiveProps(props){
        this.setState({inputValue: props.inputValue})
    }

    handleInputChange = (event) => {
        // const inputValue = newValue.replace(/\W/g, '');
        this.setState({ inputValue: event.target.value },()=>{
            this.loadOptions();
            this.updateParent();
            this.setState({loaderVisible: true})
        });
        // return inputValue;
    };

    loadOptions = () => {
        if(this.props.options){
            // just add options from props
            this.setState({options: this.props.options},()=>{
                this.setState({resultsVisible: 'inline-block'});
            // callback(this.filterItems(inputValue));
            });
        }else{
            console.log('fl', this.props.filters);
            let filters;
            if(this.state.applyFilter){
                filters = this.props.filters;
            }
            // get options from api
            Service.get(this.props.route, {filter: this.state.inputValue, filters: filters}, (res)=>{
                if(res.success){
                    let c = [];
                    res.data.map((item)=>{
                        c.push({id: item.id, label: item.label});
                    });
                    this.setState({options: c},()=>{
                        this.setState({resultsVisible: 'inline-block'});
                    // callback(this.filterItems(inputValue));
                    });
                    if(res.data.length > 0){
                        this.setState({loaderVisible: false})
                    }
                }
            })
        }
    };

    componentWillMount(){
        document.addEventListener('mousedown', this.clickOutside, false)
    }

    componentWillUnmount(){
        document.removeEventListener('mousedown', this.clickOutside, false)
    }

    updateParent=()=>{
        if(this.state.selectedData.length > 0){
            this.props.onChange(this.state.selectedData);
            this.setState({selectedData: []})
        }else{
            if(this.props.forced){
                this.props.onChange([{id: this.state.inputValue, value: this.state.inputValue}])
            }
        }
        
    }

    clearData=()=>{
        this.props.onChange([]);
        this.setState({selectedData: []})
    }

    addItem=(id, label, cityName, pttNo, townName)=>{
        let p = this.state.selectedData;
        p.push({value: id, label: label, city_name: cityName, ptt_no: pttNo, town_name: townName});
        console.log(id, label);
        this.setState({selectedData: p},()=>{
            if(this.props.type === 'single'){
                this.setState({resultsVisible: 'none'});
            }
            this.updateParent();
            
           // console.log(this.state.selectedData);
        });
    }

    selectItem=(event)=>{
        let id = event.target.id;
        let label = event.target.dataset.label;

        let cityName = event.target.getAttribute('data-city-name');
        let pttNo = event.target.getAttribute('data-ptt-no');
        let townName = event.target.getAttribute('data-town-name');

        // console.log("city NAME", event.target.getAttribute('data-city-name'))
        // console.log("ptt NUMBER", event.target.getAttribute('data-ptt-no'))
        // console.log("town NAme", event.target.getAttribute('data-town-name'))

        console.log('ff', id, label);
        if(this.props.type === 'single'){
            console.log('ai', this.state.selectedData,this.state.selectedData.length)
            if(this.state.selectedData.length > 0){
                this.setState({selectedData: []},()=>{
                    this.addItem(id, label, cityName, pttNo, townName);
                });
                return;
            }
            
        }
        
        if(this.isExisting(event.target.id)){
            return;
        }
        this.addItem(event.target.id, event.target.dataset.label, event.target.getAttribute('data-city-name'), event.target.getAttribute('data-ptt-no'), event.target.getAttribute('data-town-name'));
        
    }

    clickOutside=(event)=>{
        if(this.node.contains(event.target)){
            return;
        }
        this.setState({resultsVisible: 'none'});
    }

    removeItem=(event)=>{
        let p = this.state.selectedData;
        for(let i in p){
            if(p[i].value === event.target.id){
                p.splice(i, 1);
            }
        }
        this.setState({selectedData: p},()=>{
            this.updateParent();
            //console.log(this.state.selectedData);
        });
    }

    isExisting=(id)=>{
        
        let p = this.state.selectedData;
        for(let i in p){
            if(p[i].value === id){
                return true;
            }
        }
        return false;
    }

    changeApplyFilter=()=>{
        this.setState({applyFilter: !this.state.applyFilter});
    }

  render() {
    return (
        <div className="selector">
            
            <input onChange={this.handleInputChange} onClick={this.handleInputChange} className="selector-input" type="text" value={this.state.inputValue} />
            <div className="selector-loader-container">
                <div className="selector-loader">
                    <Loader
                        type="Oval" 
                        color="#000000"
                        height={20}
                        width={20}
                        timeout={100000} //3 secs
                        visible={this.state.loaderVisible}
                    />
                </div>
            </div>
           
           
            {this.props.filterOptional === true &&
                <span className="selector-filter">
                    <input type="checkbox" name={this._rootNodeID} defaultChecked={this.props.applyFilter} onChange={this.changeApplyFilter} />
                    <label htmlFor={this._rootNodeID}> {this.props.filterLabel}</label>
                </span>
            }
            {/* <div className="selector-clear" onClick={this.clearData}>&#215;</div> */}
            <div className="selector-options" style={{display: this.state.resultsVisible}} ref={node =>this.node = node}>
            {this.state.options.map(function(item){
                    if(!this.isExisting(item.id)){
                        return(
                            <span data-city-name={item.id.city_name} data-ptt-no={item.id.ptt_no} data-town-name={item.id.town_name} id={item.id} onClick={this.selectItem} data-label={item.label}>{item.label}</span>
                        )
                    }
                }, this)
            }
            </div>
            {/* <div className="selector-selected">
                {this.state.selectedData.map(function(item){
                    
                        return(
                            <div  className="keyword">{item.label}<button id={item.value} onClick={this.removeItem} >&#215;</button></div>
                        )
                    
                    }, this)
                }
            </div> */}
        
        

        </div>    
    )
  }
}

export default Selector;