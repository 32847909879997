import React, { Component, Fragment } from 'react';
import Service from '../components/Service';
import {Auth} from '../components/Auth';
import {Link} from 'react-router-dom';
import {Cart} from '../components/Cart';
import Shipping from '../components/Shipping';
import InputMask from 'react-input-mask';
import Selector from '../components/Selector';
import renderHTML from 'react-render-html';
import T from '../components/Translate';
import {t} from '../components/Translate';
import Select from 'react-select';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import {Store} from '../components/Store';
import {NotificationManager} from 'react-notifications';
import { StoreContext } from '../context/StoreContext';
import ReCAPTCHA from "react-google-recaptcha";

var validator = require("email-validator");

class CartPage extends Component {
    static contextType = StoreContext;

    constructor(props){
        super(props);
        this.state = {
            products: [],
            shippingInfo: '',
            name: '',
            country: '',
            continent: '',
            billingAddress: '',
            municipality: '',
            city: '',
            zipCode: '',
            phone: '',
            email: '',
            note: '',
            hasShippingAddress: false,
            touAccept: false,
            shippingName: '',
            shippingCountry: '',
            shippingContinent: '',
            shippingAddress: '',
            shippingMunicipality: '',
            shippingCity: '',
            shippingZipCode: '',
            shippingPhone: '',
            shippingEmail: '',
            shippingPrice: '',
            subtotal: '', 
            total: '',
            cartItems: [],
            shippingPriceContinent: 'Serbia',
            selectedOption: '',
            selectedShippingOption: '',
            countries: [],
            nameError: 'none',
            billingAddressError: 'none',
            cityError: 'none',
            municipalityError: 'none',
            phoneError: 'none',
            emailError: 'none',
            zipCodeError: 'none',
            countryError: 'none',
            paymentMethodError: 'none',
            shippingNameError: 'none',
            shippingAddressError: 'none',
            shippingCityError: 'none',
            shippingMunicipalityError: 'none',
            shippingPhoneError: 'none',
            shippingZipCodeError: 'none',
            shippingCountryError: 'none',
            shippingEmailError: 'none',
            inputErrors: 'none',
            productsError: 'none',
            touAcceptError: 'none',
            paymentMethod: '',
            version:'',
            merchantId: '',
            terminalId: '',
            totalAmount: '',
            currency: '',
            locale: '',
            orderId: '',
            sd: '',
            purchaseTime: '',
            purchaseDesc: '',
            signature: '',
            paymentGateway: '',
            hash: '',
            rnd: '',
            orderId: '',
            amount: '',
            okUrl: '',
            failUrl: '',
            clientId: '',
            shopUrl: '',
            hashAlgorithm: '',
            transactionType: '',
            storeType: '',
            currency: '',
            lang: '',
            encoding: '',
            orderHash: '',
            googleRecaptcha: false
        }
        this.formRef = React.createRef();
    }

    componentDidMount(){
        this.getShippingInfo();
        this.getCartItems();
        this.getCountries();
        setInterval(function(){
            this.forceUpdate();
        }.bind(this), 200);
    }

    handleInput=(event)=>{
        this.setState({[event.target.id]: event.target.value}); 
    }

    getCartItems=()=>{
        let cartItems = Cart.getCart();
        Service.get('/orders/cart',{cartItems, shippingContinent: this.state.shippingPriceContinent}, (res)=>{
            if(res.success){
                this.setState({products: res.cart.products, total: res.cart.total, subtotal: res.cart.subTotal, shippingPrice: res.cart.shipping, paymentGateway: res.cart.paymentGateway},()=>{
                    // if(this.state.paymentGateway === 0){
                    //     this.paymentMethodWithoutCC();
                    // }
                });
            }
            if(res.error === 'no_cart_items'){
                this.setState({products: []})
            }
        })
    }

    removeProduct=(productId)=>{
        Cart.removeFromCart(productId);
        this.getCartItems();    
    }

    handleShippingAddress = event => {
        this.setState({ hasShippingAddress: event.target.checked },()=>{
            if(this.state.hasShippingAddress === false && this.state.continent){
                this.setState({shippingPriceContinent: this.state.continent, selectedShippingOption: ''},()=>{
                    this.getCartItems();
                })
            }
        })
    }
    
    handleTouAccept = () => {
        this.setState({touAccept: !this.state.touAccept})
    }

    getShippingInfo=()=>{
        Service.get('/store/getShippingInfo',{}, (res)=>{
            if(res.success){
                this.setState({shippingInfo: res.info.shipping_info},()=>{
                })
            }
        })
    }

    createOrder=()=>{
        if(this.context.store.payment_cc === 1 && this.context.store.payment_type === 2){
            if(!this.state.googleRecaptcha){
                return;
            }
        }
        let checked = true;
        let checkedProducts = true;

        // Products validator
        if(!this.state.products.length){
            
            this.setState({productsError: 'block'});
            checkedProducts = false;
        }else{
            this.setState({productsError: 'none'});
        }

        // Terms of use accept
        if(!this.state.touAccept){
            this.setState({touAcceptError: 'block'});
            checked = false;
        }else{
            this.setState({touAcceptError: 'none'});
        }

        // User informations validator
        if(!this.state.name){
            this.setState({nameError: 'block'});
            checked = false;
        }else{
            this.setState({nameError: 'none'});
        }

        if(!this.state.billingAddress){
            this.setState({billingAddressError: 'block'});
            checked = false;
        }else{
            this.setState({billingAddressError: 'none'});
        }

        if(!this.state.city){
            this.setState({cityError: 'block'});
            checked = false;
        }else{
            this.setState({cityError: 'none'});
        }

        if(!this.state.municipality){
            this.setState({municipalityError: 'block'});
            checked = false;
        }else{
            this.setState({municipalityError: 'none'});
        }

        if(!this.state.email || !validator.validate(this.state.email) ){
            this.setState({emailError: 'block'});
            checked = false;
        }else{
            this.setState({emailError: 'none'});
        }

        if(!this.state.phone || this.state.phone.length < 8){
            this.setState({phoneError: 'block'});
            checked = false;
        }else{
            this.setState({phoneError: 'none'});
        }

        if(!this.state.zipCode){
            this.setState({zipCodeError: 'block'});
            checked = false;
        }else{
            this.setState({zipCodeError: 'none'});
        }
        
        if(!this.state.country){
            this.setState({countryError: 'block'});
            checked = false;
        }else{
            this.setState({countryError: 'none'});
        }

        if(!this.state.paymentMethod){
            console.log("kristina djord", this.state.paymentMethod)
            this.setState({paymentMethodError: 'block'});
            checked = false;
        }else{
            console.log("kristina djord", this.state.paymentMethod)
            this.setState({paymentMethodError: 'none'});
        }

        // Shipping data alert
        if(this.state.hasShippingAddress){
            if(!this.state.name){
                this.setState({shippingNameError: 'block'});
                checked = false;
            }else{
                this.setState({shippingNameError: 'none'});
            }
            if(!this.state.shippingAddress){
                this.setState({shippingAddressError: 'block'});
                checked = false;
            }else{
                this.setState({shippingAddressError: 'none'});
            }
            if(!this.state.shippingCity){
                this.setState({shippingCityError: 'block'});
                checked = false;
            }else{
                this.setState({shippingCityError: 'none'});
            }
            if(!this.state.shippingMunicipality){
                this.setState({shippingMunicipalityError: 'block'});
                checked = false;
            }else{
                this.setState({shippingMunicipalityError: 'none'});
            }
            if(!this.state.shippingPhone || this.state.shippingPhone.length < 8){
                this.setState({shippingPhoneError: 'block'});
                checked = false;
            }else{
                this.setState({shippingPhoneError: 'none'});
            }
            if(!this.state.shippingZipCode){
                this.setState({shippingZipCodeError: 'block'});
                checked = false;
            }else{
                this.setState({shippingZipCodeError: 'none'});
            }
            if(!this.state.shippingCountry){
                this.setState({shippingCountryError: 'block'});
                checked = false;
            }else{
                this.setState({shippingCountryError: 'none'});
            }
            if(!this.state.shippingEmail){
                this.setState({shippingEmailError: 'block'});
                checked = false;
            }else{
                this.setState({shippingEmailError: 'none'});
            }
        }
    
        if(!checked){
            this.setState({inputErrors: 'block'});
            return; 
        }else{
            this.setState({inputErrors: 'none'});
        }

        if(!checkedProducts){
            return;
        }

        Service.createOrder({
            cartItems: Cart.getCart(),
            name: this.state.name,
            billingAddress: this.state.billingAddress,
            city: this.state.city,
            municipality: this.state.municipality,
            phone: this.state.phone,
            email: this.state.email,
            zipCode: this.state.zipCode,
            country: this.state.country,
            note: this.state.note,
            hasShippingAddress: this.state.hasShippingAddress,
            shippingName: this.state.shippingName,
            shippingAddress: this.state.shippingAddress,
            shippingCity: this.state.shippingCity,
            shippingMunicipality: this.state.shippingMunicipality,
            shippingPhone: this.state.shippingPhone,
            shippingZipCode: this.state.shippingZipCode,
            shippingCountry: this.state.shippingCountry,
            shippingEmail: this.state.shippingEmail,
            shippingContinent: this.state.shippingPriceContinent,
            paymentMethod: this.state.paymentMethod
        },(res)=>{
            
            if(res.items_not_available){
                Cart.reduceCart(res.items_not_available);
                this.getCartItems();
                NotificationManager.warning(<T text="warningNotAvailableItem"/>)
                return;
            }else if(this.state.paymentMethod == 'cc'){
                if(this.context.store.payment_type === 2){
                    this.setState({
                        hash: res.data.form.hash,
                        rnd: res.data.form.rnd,
                        orderId: res.data.form.orderId,
                        amount: res.data.form.amount,
                        okUrl: res.data.form.urlSuccess,
                        failUrl: res.data.form.urlFail,
                        clientId: res.data.form.clientId,
                        shopUrl: res.data.form.shopUrl,
                        hashAlgorithm: res.data.form.hashAlgorithm,
                        transactionType: res.data.form.transactionType,
                        storeType: res.data.form.storeType,
                        currency: res.data.form.currency,
                        lang: res.data.form.lang,
                        encoding: res.data.form.encoding,
                        // paymentMethod: res.form.paymentMethod,  
                        orderHash: res.hash
                    },()=>{
                        var form = this.formRef.current;
                        form.submit();
                    });
                }else{
                    window.location.href= res.payment_url;
                }
            }else{
                window.location.href= '/success/'+res.hash;
            }
        });
    }


    changeQuantity = (product, value) => {
        if(value === 'add'){
            Cart.addToCart(product);
        }

        if(value === 'remove'){
            Cart.removeOneFromCart(product);
        }

        this.getCartItems();
    }

    getCartProductsQuantities(cartItems){
        let r = [];
        for(var p in cartItems){
            r.push({id: cartItems[p].id, quantity: cartItems[p].quantity});
        }
        return r;
    }

    handleChange = (selectedOption) => {
        this.setState({ selectedOption });
        this.setState({
            country: selectedOption.value
        },()=>{
            this.getContinent(this.state.country, 'billing');
        })
    };

    handleShippingChange = (selectedShippingOption) => {
        this.setState({ selectedShippingOption });
        this.setState({
            shippingCountry: selectedShippingOption.value
        },()=>{
            this.getContinent(this.state.shippingCountry, 'shipping');
        })
    };

    getCountries=()=>{
        let countries = [];
        Service.get('/orders/getCountries',{}, (res)=>{
            if(res.success){
                res.countries.map(item =>{
                    countries.push({value: item.value, label: item.lable})
                })
            }
        })
        this.setState({countries},()=>{
        })
    }

    getContinent=(countryCode, type)=>{
        Service.get('/getContinent',{
            countryCode: countryCode
        }, (res)=>{
            if(res.success){
                if(type === 'billing'){
                    this.setState({shippingPriceContinent: res.continent, continent: res.continent},()=>{
                        this.getCartItems();
                    })
                }else if(type === 'shipping'){
                    this.setState({shippingPriceContinent: res.continent, shippingContinent: res.continent},()=>{
                        this.getCartItems();
                    })
                }
                
            }
        })
    }

    handleInputData=(event)=>{
        this.setState({[event.target.getAttribute('data-id')]: event.target.value});
        
    }

    setRecaptcha=(value)=>{
        if(value){
            this.setState({googleRecaptcha: true})
        }
    }

    // paymentMethodWithoutCC=()=>{
    //     this.setState({paymentMethod: 'pp'})
    // }

    render() {
    const { selectedOption } = this.state;
    const { selectedShippingOption } = this.state;

    return (
        <div className="content cart">
            <h1 className="color-header"><T text="cartCart"/></h1>
            { this.state.products.length > 0 &&
                <div className="cart-products-header">
                    <div className="cart-products-header-image"></div>
                    <div className="cart-products-header-title"><T text="cartProduct"/></div>
                    <div className="cart-products-header-price"><T text="cartPrice"/></div>
                    <div className="cart-products-header-quantity"><T text="cartQuantity"/></div>
                    <div className="cart-products-header-total"><T text="cartTotal"/></div>
                    <div className="cart-products-header-remove">x</div>
                </div>
            }
                    { this.state.products.length <= 0 ?
                        <p><T text="cartEmptyMessage"/></p>  
                        :
                        <Fragment>
                            {this.state.products.map((item, index)=>{
                                return(
                                    <div className="cart-products-body">
                                        <div className="cart-products-body-image"><img src={item.image} /></div>
                                        <div className="cart-products-body-title">{item.name}</div>
                                        <div className="cart-products-body-price">{Service.price(item.unit_price)}</div>
                                        <div className="cart-products-body-quantity">
                                            <button className="add-remove" onClick={()=>{this.changeQuantity(item.id, 'remove')}}>-</button>
                                            <span>{item.cart_quantity}</span>
                                            <button className="add-remove" onClick={()=>{this.changeQuantity(item.id, 'add')}}>+</button>    
                                        </div>
                                        <div className="cart-products-body-total">{Service.price(item.cart_product_total)}</div>
                                        <div className="cart-products-body-remove"><i className="fas fa-minus-circle" onClick={()=>{this.removeProduct(item.id)}}></i></div>
                                    </div>
                                        // <tr>
                                        //     <td className="col-1"><img src={item.image} /> </td>
                                        //     <td>{item.name}</td>
                                        //     <td>{item.unit_price}</td>
                                        //     <td>
                                        //         <button className="add-remove" onClick={()=>{this.changeQuantity(item.id, 'remove')}}>-</button>
                                        //         <span>{item.cart_quantity}</span>
                                        //         <button className="add-remove" onClick={()=>{this.changeQuantity(item.id, 'add')}}>+</button>
                                        //     </td>
                                        //     <td>{item.cart_product_total}</td>
                                        //     <td><i className="fas fa-minus-circle" onClick={()=>{this.removeProduct(item.id)}}></i></td>
                                        // </tr>
                                    )
                                })
                            }
                        </Fragment>
                    }

            { this.state.products.length > 0 &&
                <div className="cart-total">
                    <p><T text="cartSubtotal"/>: {Service.price(this.state.subtotal)}</p>
                    <p><T text="cartShipping"/>: {Service.price(this.state.shippingPrice)}</p>
                    <h3><strong><T text="cartTotal"/>: {Service.price(this.state.total)}</strong></h3>
                    <span><T text="vat_included"/></span>
                </div>
            }
            { this.state.products.length > 0 &&
            <div className="container">
                    <label htmlFor="name"><T text="cartName"/><span className='mandatory-inputs'>*</span></label>
                    <input type="text" id="name" value={this.state.name} onChange={this.handleInput}/>
                    <p className="error" style={{display: this.state.nameError}}><T text="no_name"/></p>

                    <label htmlFor="billingAddress"><T text="cartAddress"/><span className='mandatory-inputs'>*</span></label>
                    <input type="text" id="billingAddress" value={this.state.billingAddress} onChange={this.handleInput}/>
                    <p className="error" style={{display: this.state.billingAddressError}}><T text="no_billing_address"/></p>

                    <label htmlFor="municipality"><T text="cartMunicipality"/><span className='mandatory-inputs'>*</span></label>
                    <input type="text" id="municipality" value={this.state.municipality} onChange={this.handleInput}/>
                    <p className="error" style={{display: this.state.municipalityError}}><T text="no_municipality"/></p>

                    <label htmlFor="zipCode"><T text="cartZipCode"/><span className='mandatory-inputs'>*</span></label>
                    <input type="text" id="zipCode" value={this.state.zipCode} onChange={this.handleInput}/>
                    <p className="error" style={{display: this.state.zipCodeError}}><T text="no_zip_code"/></p>

                    <label htmlFor="city"><T text="cartCity"/><span className='mandatory-inputs'>*</span></label>
                    <input type="text" id="city" value={this.state.city} onChange={this.handleInput}/>
                    <p className="error" style={{display: this.state.cityError}}><T text="no_city"/></p>

                    <label htmlFor="country"><T text="cartCountry"/><span className='mandatory-inputs'>*</span></label>
                    <Select
                        value={selectedOption}
                        onChange={this.handleChange}
                        options={this.state.countries}
                    />
                    <p className="error" style={{display: this.state.countryError}}><T text="no_country"/></p>

                    <label htmlFor="phone"><T text="cartPhone"/><span className='mandatory-inputs'>*</span></label>
                    <input type="text" id="phone" value={this.state.phone} onChange={this.handleInput}/>
                    <p className="error" style={{display: this.state.phoneError}}><T text="no_phone"/></p>
        
                    <label htmlFor="email"><T text="cartEmail"/><span className='mandatory-inputs'>*</span></label>
                    <input type="text" id="email" value={this.state.email} onChange={this.handleInput}/>	
                    <p className="error" style={{display: this.state.emailError}}><T text="no_email"/></p>
        
                    {/* Required field */}
                    <p>
                        {renderHTML(t("required_fields"))}
                    </p>

                    <label htmlFor="note"><T text="cartNote"/></label>
                    <textarea id="note" value={this.state.note} onChange={this.handleInput} placeholder="Napomena za isporuku..."></textarea>

                    <input id='scb' type="checkbox" style={{display: 'inline', verticalAlign: 'middle'}} value={this.state.hasShippingAddress} onChange={this.handleShippingAddress} />
                    <label htmlFor="scb" style={{display: 'inline', verticalAlign: 'top', width: '97%'}}> <T text="cartShippingAddressMessage"/></label>

                    {this.state.hasShippingAddress === true &&                                  
                        <div>
                            <div className="cart-form-first">
                                <label htmlFor="shippingName"><T text="cartShippingName"/><span className='mandatory-inputs'>*</span></label>
                                <input type="text" id="shippingName" value={this.state.shippingName} onChange={this.handleInput}/>
                                <p className="error" style={{display: this.state.shippingNameError}}><T text="no_shipping_name"/></p>

                                <label htmlFor="shippingAddress"><T text="cartShippingAddress"/><span className='mandatory-inputs'>*</span></label>
                                <input type="text" id="shippingAddress" value={this.state.shippingAddress} onChange={this.handleInput}/>
                                <p className="error" style={{display: this.state.shippingAddressError}}><T text="no_shipping_address"/></p>

                                <label htmlFor="shippingMunicipality"><T text="cartMunicipality"/><span className='mandatory-inputs'>*</span></label>
                                <input type="text" id="shippingMunicipality" value={this.state.shippingMunicipality} onChange={this.handleInput}/>
                                <p className="error" style={{display: this.state.shippingMunicipalityError}}><T text="no_shipping_municipality"/></p>

                                <label htmlFor="shippingZipCode"><T text="cartZipCode"/><span className='mandatory-inputs'>*</span></label>
                                <input type="text" id="shippingZipCode" value={this.state.shippingZipCode} onChange={this.handleInput}/>
                                <p className="error" style={{display: this.state.shippingZipCodeError}}><T text="no_shipping_zip_code"/></p>
                            </div>
                            <div className="cart-form-second">
                                <label htmlFor="shippingCity"><T text="cartCity"/><span className='mandatory-inputs'>*</span></label>
                                <input type="text" id="shippingCity" value={this.state.shippingCity} onChange={this.handleInput}/>
                                <p className="error" style={{display: this.state.shippingCityError}}><T text="no_shipping_city"/></p>
                            
                                <label htmlFor="shippingCountry"><T text="cartCountry"/><span className='mandatory-inputs'>*</span></label>
                                <Select
                                    value={selectedShippingOption}
                                    onChange={this.handleShippingChange}
                                    options={this.state.countries}
                                />
                                <p className="error" style={{display: this.state.shippingCountryError}}><T text="no_shipping_country"/></p>

                                <label class="cart-form-shipping-phone" htmlFor="shippingPhone"><span className='mandatory-inputs'>*</span><T text="cartShippingPhone"/></label>
                                <input type="text" id="shippingPhone" value={this.state.shippingPhone} onChange={this.handleInput}/>
                                <p className="error" style={{display: this.state.shippingPhoneError}}><T text="no_shipping_phone"/></p>
            
                                <label htmlFor="shippingEmail"><T text="cartShippingEmail"/><span className='mandatory-inputs'>*</span></label>
                                <input type="text" id="shippingEmail" value={this.state.shippingEmail} onChange={this.handleInput}/>
                                <p className="error" style={{display: this.state.shippingEmailError}}><T text="no_shipping_email"/></p>
                            </div>
                        </div>
                    }

                    <div className="cart-inputs-billing">
                        {/* CC */}
                        {this.context.store.payment_cc === 1 ?
                            <Fragment>
                                <input type="radio" className="cart-input-radio" name="billing-method" id="paymentMethod-cc" data-id="paymentMethod" value="cc" onChange={this.handleInputData}/>
                                <label className="cart-label-radio" htmlFor="paymentMethod-cc">
                                    <T text="creditCart"/>
                                </label>
                            </Fragment>
                            :
                            null
                        }
                        
                        {/* PP */}
                        {this.context.store.payment_pp === 1 ?
                            <Fragment>
                                <input type="radio" className="cart-input-radio" name="billing-method" id="paymentMethod-pp" data-id="paymentMethod" value="pp" onChange={this.handleInputData}/>
                                <label className="cart-label-radio" htmlFor="paymentMethod-pp">
                                    <T text="payOnDelivery"/>
                                </label>
                            </Fragment>
                            : 
                            null
                        }
                        
                        {/* UP */}
                        {this.context.store.payment_up === 1 ?
                            <Fragment>
                                <input type="radio" className="cart-input-radio" name="billing-method" id="paymentMethod-up" data-id="paymentMethod" value="up" onChange={this.handleInputData}/>
                                <label htmlFor="paymentMethod-up">
                                    <T text="paymentSlip"/>
                                </label>
                            </Fragment>
                            : 
                            null
                        }
                        <p className="error" style={{display: this.state.paymentMethodError}}><T text="no_payment_method"/></p>
                    </div>

                    {/* Terms of use accept */}
                    <div className="cart-terms-of-use" style={{margin: '1em 0'}}>
                        <input id='touCb' type="checkbox" className="cart-terms-checkbox" value={this.state.touAccept} onChange={this.handleTouAccept} />
                        <label htmlFor="touCb" className="cart-terms-label"> {renderHTML(t("touAccept"))}</label>
                        <p className="error" style={{display: this.state.touAcceptError}}><T text="no_tou_accept"/></p>
                    </div>

                    {/* google ReCAPTCHA */}
                    {(this.context.store.payment_cc === 1 && this.context.store.payment_type === 2) &&
                        <ReCAPTCHA
                            sitekey="6LfJdk4fAAAAACnZNP9SsIz1-ozsfmu0c3dQsy13"
                            onChange={this.setRecaptcha}
                        />
                    }
            </div>
            }

            <div>
                {/* {this.state.paymentGateway === 1 &&
                    <Fragment>
                        <input type="radio" className="cart-input-radio" name="payment-method" id="paymentMethod-cc" data-id="paymentMethod" value="cc" onChange={this.handleInputData}/>
                        <label for="paymentMethod-cc" className="cart-input-radio-label">
                            <div>
                                <h4>{ta("payment_cart")}</h4>
                            </div>
                        </label>

                        <input type="radio" className="cart-input-radio" name="payment-method" id="paymentMethod-pp" data-id="paymentMethod" value="pp" onChange={this.handleInputData}/>
                            <label for="paymentMethod-pp" className="cart-input-radio-label">
                                <div>
                                    <h4>{ta("on_delivery")}</h4>
                                </div>
                            </label>
                    </Fragment>
                } */}

                {/* {this.state.paymentGateway === 0 &&
                    <Fragment>
                        <input type="radio" className="cart-input-radio" name="payment-method" id="paymentMethod-pp" data-id="paymentMethod" value="pp" checked='checked'/>
                            <label for="paymentMethod-pp" className="cart-input-radio-label">
                                <div>
                                    <h4>Pouzećem</h4>
                                </div>
                            </label>
                    </Fragment>
                } */}
                
            </div>
                
            <form action={Service.paymentUrl} ref={this.formRef} id="form" method='POST'>
                <input type="hidden" name="clientid" value={this.state.clientId}/>
                <input type="hidden" name="storetype" value={this.state.storeType} />
                <input type="hidden" name="hashAlgorithm" value={this.state.hashAlgorithm} />
                <input type="hidden" name="hash" value={this.state.hash} />
                <input type="hidden" name="trantype" value={this.state.transactionType} />
                <input type="hidden" name="amount" value={this.state.amount} />
                <input type="hidden" name="currency" value={this.state.currency} />
                <input type="hidden" name="oid" value={this.state.orderId} />
                <input type="hidden" name="okUrl" value={this.state.okUrl}/>
                <input type="hidden" name="failUrl" value={this.state.failUrl} />
                <input type="hidden" name="lang" value={this.state.lang} />
                <input type="hidden" name="rnd" value={this.state.rnd} />
                <input type="hidden" name="encoding" value={this.state.encoding} />
                <input type='hidden' name='shopurl' value={this.state.shopUrl} />
            </form>

            { this.state.products.length > 0 &&
                <div className="shop-button-container">
                    <button className="shop-button-order" onClick={this.createOrder}><T text="cartButton"/></button>
                    {this.state.shippingInfo &&
                        <div className="cart-shipping-info">{renderHTML(this.state.shippingInfo)}</div>
                    }
                </div>
            }
            <p className="error" style={{display: this.state.inputErrors, textAlign: 'center', width: '100%', position: 'relative', fontSize: '1em'}}><T text="no_input"/></p>
            <p className="error" style={{display: this.state.productsError, textAlign: 'center', width: '100%', position: 'relative', fontSize: '1em'}}><T text="no_products"/></p>
        </div>

        );   
        }
    }

export default CartPage;