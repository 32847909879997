import React, { Component } from 'react';
import Service from '../components/Service';
import T from '../components/Translate';

class UpdatePassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            password: '',
            password2: '',
            message: ''
        }
    }

    updatePassword=()=>{
       Service.get('/users/updatePassword',{activationCode: this.props.match.params.activationCode},(res)=>{
            if(res.success){
                this.setState({activated: 1});
                this.props.history.push('/registracija/success');
            }else{
                this.setState({activated: 2});
            }
       })
    }

    handlePassword=(event)=>{
        this.setState({password: event.target.value, message: ''});
    }
    handlePassword2=(event)=>{
        this.setState({password2: event.target.value, message: ''});
    }

    submitResetPassword=()=>{
        Service.get('/users/changePassword',{
            hash: this.props.match.params.hash,
            userId: this.props.match.params.userId,
            password: this.state.password,
            passwordConfirm: this.state.password2
         },(res)=>{
            if(res.success){
                this.props.history.push('/registracija/successPasswordUpdate');
            }else{
                if(res.error == 'paswords_do_not_match'){
                    this.setState({message: "Passwords don't match."})
                }
            }
        })
    }

    render(){
        return(
            <div className='content'>
                <h1><T text="change_password"/></h1>
                <p><T text="new_password"/></p>
                <input type="password" value={this.state.password} onChange={this.handlePassword} />
                <p><T text="confirm_new_password"/></p>
                <input type="password" value={this.state.password2} onChange={this.handlePassword2} />
                {this.state.message &&
                    <h3>{this.state.message}</h3>
                }
                <button className="default-button" type="button" onClick={this.submitResetPassword}><T text="submit"/></button>
            </div>
        )
    }
}

export default UpdatePassword;