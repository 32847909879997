import React, { Component } from 'react';
import Categories from '../components/Categories';


class Rules extends Component{

    render(){
        return(
            <div>
                <div className="left-content">
                        <h3>Kategorije</h3>
                        <Categories />
                </div>
                <div className="right-content">
                   
                </div>
            </div>
        );
    }

}

export default Rules;