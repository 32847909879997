import React, { Component, Fragment } from 'react';
import { Cart } from '../components/Cart';
import Categories from '../components/Categories';
import Service from '../components/Service';
import StarRatings from 'react-star-ratings';
// import {BrowserRouter as Router, Route, Link, Read} from 'react-router-dom';
import {Auth} from '../components/Auth';
import {Config} from '../components/Config';
import Sticker from '../components/Sticker';
// import Pager from '../components/Pager';
import ReactPaginate from 'react-paginate';
import duix from 'duix';
import {NotificationManager} from 'react-notifications';
import {withRouter} from 'react-router-dom';
//import ReactGA from 'react-ga';

const queryString = require('query-string');

function capitalizeFirstLetterCategory(string) {
    if(string){
        let check = parseInt(string.charAt(0));
        console.log(check);
        if(isNaN(check)){
            let r = string.replace(/-/g,' ');
            console.log('e', r);
            return r.charAt(0).toUpperCase() + r.slice(1);
        }
        return string;
    }

    return 'Knjige';
}


// const Info = props => (
  
//     <div className="product-info"><span className="product-info-label" >{props.label}: </span> {props.value} </div>
 
// );

// function images(image){
//     return 'http://api.pp.cekaonica.com/media/'+ image + '.jpg';
//   }

class Products extends Component {

    

    constructor(props) {
        super(props);
        this.state = {
            products: [],
            numberOfProducts: 0,
            page: 1,
            perPage: Config.perPage,
            currentFilter: '',
            pageUpNumber: 0,
            pageDownNumber: 0,
            noProductsMessage: 'Učitavanje',
            categories: []
        }
        
    }

    

    componentDidMount=() =>{
        if(this.getParams.page){
            this.page = parseInt(this.getParams.page);
        }else{
            this.page = 1;
        }
        this.getAllCategories();

        if(this.props.match.params.filter){
            this.filter = this.props.match.params.filter;
        }
        if(this.props.match.params.a){
            this.params.a = this.props.match.params.a;
        }
        if(this.props.match.params.b){
            this.params.a = this.props.match.params.b;
        }

        //ReactGA.initialize(Service.gaCode);
        //ReactGA.pageview(window.location.pathname + window.location.search);
        //console.log('ga', window.location.pathname + window.location.search)
        //this.updateProducts();

    }



    componentWillReceiveProps(newProps){
        let getParams = queryString.parse(newProps.location.search);
        if(getParams.page){
            this.page = parseInt(getParams.page);
        }else{
            this.page = 1;
        }

        if(newProps.match.params.filter){
            this.filter = newProps.match.params.filter;
        }
        if(newProps.match.params.a){
            this.params.a = newProps.match.params.a;
        }
        if(newProps.match.params.b){
            this.params.a = newProps.match.params.b;
        }
        setTimeout(()=>{
            this.updateProducts();
        },100);
        console.log('update 2',newProps.search, getParams.page, newProps);
        console.log('f',newProps.match.params.filter,newProps.match.params.a,newProps.match.params.b);
    }


    addToCart=(product)=>{
      Cart.addToCart(product);
      Cart.getCart();
    }

    removeOneFromCart(product){
      Cart.removeOneFromCart(product);
      Cart.getCart();
    }
  
    removeFromCart(product){
      Cart.removeFromCart(product);
      Cart.getCart();
    }

    getParams = queryString.parse(this.props.location.search);
    page;
    filter;
    params = {};

    compareByStatus(a, b) {
        const bandA = a.status;
        const bandB = b.status;

        let comparison = 0;
        if (bandA > bandB) {
            comparison = 1;
        } else if (bandA < bandB) {
            comparison = -1;
        }
        return comparison;
    }

    sortProducts(products){
        // let p = products;
        let a = [];
        let b = [];
        for(var i in products){
            if(products[i].status >= 3){
                b.push(products[i])
            }else{
                a.push(products[i])
            }
        }
        a.sort((a, b) => (a.published_at < b.published_at) ? 1 : -1);
        b.sort((a, b) => (a.published_at < b.published_at) ? 1 : -1);

        return a.concat(b);
    }

    addToWishlist(product){

    if(Auth.isAuthenticated){
        Service.addToWishlist({
            productId: product.id, 
            Auth
            },()=>{
                duix.set('wishlistItems', 1);
                duix.set('modal', 'Proizvod je dodat u listu želja.');
            });
        }else{
            alert('Morate biti ulogovani da biste koristili listu želja.');
        }
    }


    updateProducts = () => {
        // get products without any filter
        console.log('updateproducts', this.filter);
        if(!this.filter){
            if(this.state.currentFilter !== 'default'){
                // this.state.currentFilter = 'default';
                this.setState({currentFilter: 'default'})
                this.setState({page: 1})
            }
            // let getParams = queryString.parse(this.props.location.search);
            Service.get('/products/get',{page: this.page, perPage: this.state.perPage},(res)=>{
                let p = res.data.products;
                p.sort((a, b) => (a.published_at < b.published_at) ? 1 : -1);
                //p.sort((a, b) => (a.status > b.status) ? 1 : -1);
                this.setState({products: p});
                this.setState({numberOfProducts: res.data.total_products});
                //this.setState({noProductsMessage: 'Nema pronađenih proizvoda!'});
                if(res.data.products){
                    this.setState({noProductsMessage: 'Nema pronađenih proizvoda!'});
                } else {
                    this.setState({noProductsMessage: ''});
                }
                // this.setState({pageUpNumber: this.pageUp(), pageDownNumber: this.pageDown()},()=>{
                // });
            });
            return;
        }
        
        //  search
        if(this.filter === 'filter'){
            if(this.state.currentFilter !== 'filter'){
                this.setState({currentFilter: 'filter'})
                this.setState({page: 1})
            }
            Service.getProductBySearch({filter: this.params.a, perPage: this.state.perPage, page: this.page},(res)=>{
                let p = res.data.products;
                // p.sort((a, b) => (a.published_at < b.published_at) ? 1 : -1);
                // p.sort((a, b) => (a.status > b.status) ? 1 : -1);
                this.setState({products: this.sortProducts(p)});
                this.setState({numberOfProducts: res.data.total_products});
                if(res.data.products){
                    this.setState({noProductsMessage: 'Nema pronađenih proizvoda!'});
                } else {
                    this.setState({noProductsMessage: ''});
                }
                // this.setState({pageUpNumber: this.pageUp(), pageDownNumber: this.pageDown()});
            });
            return;
        }

        // get products by category 
        if(this.filter === 'kategorije'){
            if(this.state.currentFilter !== 'kategorije'){
                // this.state.currentFilter = 'kategorije';
                this.setState({currentFilter: 'kategorije'})
                this.setState({page: 1})
            }
            Service.get('/products/getByCategory',{
                    page: this.page, 
                    perPage: this.state.perPage,
                    categoryId: this.props.match.params.a
                    
                },
                (res)=>{

                    // get brand subcategories
                    let s = {};
                    let subcategories = [];
                    for(var c in this.state.categories){
                        if(this.state.categories[c].name === 'Brend'){
                            s = this.state.categories[c].subcategories;
                        }
                    }

                    for(var i in s){
                        subcategories.push(s[i].id); 
                    }

                    let p = res.data.products;
                    let n = [];
                    if(!subcategories.includes(parseInt(this.a))){
                        n = this.sortProducts(p);
                        console.log('nema');
                    }else{
                        n = p;
                    }
                    this.setState({products: n});
                    this.setState({numberOfProducts: res.data.total_products});
                if(res.data.products){
                    this.setState({noProductsMessage: 'Nema pronađenih proizvoda!'});
                } else {
                    this.setState({noProductsMessage: ''});
                }
                // this.setState({pageUpNumber: this.pageUp(), pageDownNumber: this.pageDown()});
            });
            return;
        }

        
        
      }
      getParams = queryString.parse(this.props.location.search);


    getAllCategories = () => {

        Service.get('/categories/get',{
        },(res)=>{
            this.setState({categories: res.data},()=>{
                this.updateProducts();
            });
        });
    }

    changeRating=(rate, name)=>{
        if(Auth.isAuthenticated){
            Service.rateProduct({Auth, productId: name, rating: rate},(res)=>{
                this.updateProducts();
            });
        }else{
            NotificationManager.error('Morate biti ulogovani da biste ocenili proizvod! Kliknite ovde da se ulogujete.', 'Greska:', 5000, () => {window.location='/registracija'});
            // alert('Morate biti ulogovani da biste ocenili proizvod!');
        }
    }

    changePage = page => {
        let link;
        if(page.selected){
            link = `${window.location.href.split('?')[0]}?page=${page.selected + 1}`;
            //link = `/proizvodi?page=${page.selected + 1}`;
            //link = `/proizvodi?page=${page.selected + 1}`;
           // window.location.href = link;
        }else{
             link = `${window.location.href.split('?')[0]}`;
            //link = `/proizvodi`;
            
        }
        //this.props.history.push(link);
        window.location.href = link;
    }

    render() {
        return(
            <div>
                <div className="left-content">
                    {/* <h3>Kategorije</h3> */}
                    <Categories />
                </div>
                <div className="right-content">
                <h3>{capitalizeFirstLetterCategory(this.props.match.params.b)}</h3>
                    {this.state.products.map(function(item, index){
                        //console.log('ppp');
                        let stickerNew = false;
                        return(
                            <div className="product-container" key={index} >
                                {/* Stickers */}
                                {item.categories &&
                                    item.categories.map(function(categories, index){
                                        return(
                                            <span key={index}>
                                                {categories.name === "Novi naslovi" &&
                                                    <Fragment>{stickerNew = true}</Fragment>
                                                }       
                                            </span>
                                        )}, this)
                                    
                                }
                                
                                {(item.sticker === 1 && stickerNew === false  && item.discount !== null) &&
                                    <Sticker classNameProducts="sticker-container-products" text={parseInt(item.discount)+"%"}/>
                                }
                                 {(item.sticker === 1 && stickerNew === true && item.discount !== null) &&
                                    <Sticker classNameProducts="sticker-container-products" text={parseInt(item.discount)+"%"}/>
                                }
                                {(item.sticker === 1 && stickerNew === true && item.discount === null) &&
                                    <Sticker classNameProducts="sticker-container-products" text="NOVO"/>
                                }

                                <div className="product-image-container">
                                <a href={`/proizvod/${item.id}/${item.slug}`}><img alt="" src={Service.images(item.slug.toLowerCase() + '.jpg')} /></a>
                                </div>
                                <h3>{item.title}</h3>
                                <div className="product-container-info">
                                    <div className="product-authors">
                                        {item.authors.map(function(author, index){
                                            return(
                                                <span key={index} className="authors-span">
                                                    {author.link &&
                                                        <a href={author.link}><h4>{author.name}</h4></a>
                                                    }
                                                    {!author.link &&
                                                        <h4>{author.name}</h4>
                                                    }
                                                </span>
                                            )}, this)
                                        }
                                    </div>
                                     
                                    { (item.full_price !== item.price && item.status !== 2) &&
                                        <span>
                                            <p className="old-price">{item.full_price} RSD</p>
                                            <p>{item.price} RSD </p>
                                        </span>
                                    }
                                    {(item.full_price === item.price && item.status !== 2) &&
                                        <p>{item.full_price} RSD</p>
                                    }
                                    { item.status === 1 &&
                                        <button type="button" className="add-to-cart-button" onClick={()=>{this.addToCart(item)}}>DODAJ U KORPU</button>
                                    }
                                    { item.status === 2 &&
                                        <fragment>
                                        <p>U pripremi</p>
                                        <button type="button" className="add-to-cart-button wishlist-button" onClick={()=>{this.addToWishlist(item)}}>DODAJ U LISTU ŽELJA</button>
                                        </fragment>
                                    }
                                    { item.status === 3 &&
                                        <button type="button" className="add-to-cart-button sold-out" >RASPRODATO</button>
                                    }
                                    {/* <p>{item.published_at}</p> */}
                                    { item.status !== 2 &&
                                        <StarRatings
                                        // rating={this.state.rating}
                                        //rating={item.rating}
                                        rating={item.rating}
                                        starDimension="1.6em"
                                        starSpacing=".2em"
                                        starRatedColor="#e6bc00"
                                        changeRating={this.changeRating}
                                        numberOfStars={5}
                                        name={item.id.toString()}
                                        />
                                    }
                                    
                                </div>
                            </div>
                        );
                        },this)
                    }

                    {this.state.products.length < 1 &&
                        <p className="no-products" >{this.state.noProductsMessage}</p>
                    }
                    {/* <Pager numberOfProducts={this.state.numberOfProducts} currentPage={this.page} perPage={Config.perPage}/> */}
                    <ReactPaginate
                        pageCount={Config.getNumberOfPages(this.state.numberOfProducts)}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        previousLabel={'<'}
                        nextLabel={'>'}
                        forcePage={this.page - 1}
                        containerClassName={'pager-container'}
                        activeClassName={'pager-selected'}
                        onPageChange={this.changePage}
                    />
                </div>
                
            </div>

        );
    }

}

export default withRouter(props => <Products {...props}/>);
//export default Products;