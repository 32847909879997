import Service from '../components/Service';

const lsPrefix = 'brntshp'+window.location.hostname.split('.')[0];

export const Auth = {
  isAuthenticated: false,
  userId: false,
  name: false,
  token: false,
  lastProductsPage: false,
  isAdmin: false,
  currency: 'rsd',
  
  authenticate(password, cb, cb2) {

    Service.get('/users/login',{
        email: this.email,
        password: password
    },(res)=>{
        if(res.success){

        		this.isAuthenticated = true;
                this.userId = res.data.id;
                this.name = res.data.userName;
                this.token = res.data.token;
                this.isAdmin = false;

                localStorage.setItem(lsPrefix+'token', res.data.token);
                localStorage.setItem(lsPrefix+'userId', res.data.id);
                localStorage.setItem(lsPrefix+'name', res.data.userName);
                localStorage.setItem(lsPrefix+'email', this.email);

                cb(res);
        		
        	}else{

        		this.isAuthenticated = false;
                cb2(res.info);

        	}
    });

  },

  setCurrency(cur){
    this.currency = cur;
    window.location.reload();
  },

  authenticateAdmin(password, cb, cb2) {

    Service.get('/admin/login',{
        email: this.email,
        password: password
    },(res)=>{
        if(res.success){

        		this.isAuthenticated = true;
                this.userId = res.data.id;
                this.name = res.data.userName;
                this.token = res.data.token;
                this.isAdmin = true;

                localStorage.setItem(lsPrefix+'token', res.data.token);
                localStorage.setItem(lsPrefix+'userId', res.data.userName);
                localStorage.setItem(lsPrefix+'name', res.data.name);
                localStorage.setItem(lsPrefix+'email', this.email);

                cb(res);
        		
        	}else{

        		this.isAuthenticated = false;
                this.signout();
                cb2(res.info);

        	}
    });

  },

  setLastPage(page){
      this.lastProductsPage = page;
      localStorage.setItem('lastProductsPage', this.lastProductsPage);
  },

    logout(cb=null) {

        Service.get('/users/logout',{
            userId: this.userId,
            token: this.token
        },(res)=>{

            this.isAuthenticated = false;
            this.userId = false;
            this.name = false;
            this.token = false;

            localStorage.removeItem(lsPrefix+'token');
            localStorage.removeItem(lsPrefix+'userId');
            localStorage.removeItem(lsPrefix+'name');
            localStorage.removeItem(lsPrefix+'email');

            if(cb){
                cb(res);
            }
        })
    
  },

  
  signout(cb) {
    // this.logout();
    this.isAuthenticated = false
    localStorage.removeItem(lsPrefix+'token');
    localStorage.removeItem(lsPrefix+'userId');
    localStorage.removeItem(lsPrefix+'name');
    localStorage.removeItem(lsPrefix+'email');
    setTimeout(cb, 100)
  }
}

