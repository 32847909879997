import React, { Component } from 'react';


class Fail extends Component{

    render(){
        return(
            <div>
                <h1>Transakcija neuspešna!</h1>
                <p>Molimo vas proverite unesete podatke i probajte ponovo.</p>
            </div>
        );
    }

}

export default Fail;