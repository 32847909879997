import React, { Component } from 'react';
import { Auth } from '../components/Auth';
import {Link, withRouter} from 'react-router-dom';
import duix from 'duix';
import T from '../components/Translate';

class Registration extends Component {

    constructor(props){
        super(props);
        this.state = {
            loggedIn: false,
            name: '',
            buttonText: ''
        }

    }

    unsubscribe = [];

    update=()=>{
        let name = Auth.name;
            
            if(Auth.isAuthenticated){
                this.setState({name: name, loggedIn: true});
            }else{
                this.setState({loggedIn: false}); 
            }
            duix.set('wishlistItems', 1);
            
    }
 
    componentDidMount(){
        setInterval(function(){
            this.forceUpdate();
        }.bind(this), 500);

        //this.unsubscribe[0] = duix.subscribe('wishlistItems', this.update);
        this.unsubscribe[1] = duix.subscribe('registration', this.update);
        
    }

    componentWillUnmount(){
        //this.unsubscribe[0]();
        this.unsubscribe[1]();
        //clearInterval(this.interval);
    }

    logout = () => {
        Auth.signout(()=>{
            
            // let name = Auth.name;
            // if(Auth.isAuthenticated){
            //     this.setState({name: name});
            //     this.setState({buttonText: 'Izloguj se'}); 
            //     this.setState({loggedIn: true});
            // }else{
            //     this.setState({buttonText: 'Prijava'}); 
            //     this.setState({loggedIn: false});
            // }
            this.update();
            duix.set('wishlistItems', Math.ceil(Math.random() * 100));
            this.props.history.push('/');
            
        });
    }

    render(){

        if(!this.state.loggedIn){
            return(
                <div className="registration-container">
                    <Link to="/registracija"><button className="register-button"><T text="signInBtn"/></button></Link>
                </div>
            )
        }else{
            return(
                <div className="registration-container">
                    {/* <span>{this.state.name} </span> */}
                    <div className="register-button" onClick={this.logout}>{this.state.name} / <T text="logoutBtn"/></div>
                </div>
            )
        }

    }

}

export default withRouter(Registration);