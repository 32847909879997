import React, { useEffect, useState } from 'react';
import T from '../components/Translate';

export default function AdultCheck(){
    const [adultCheckParam, setAdultCheckParam] = useState(0);
    const [display, setDisplay] = useState('block')
    const lsPrefix = 'brntshp'+window.location.hostname.split('.')[0];

    useEffect(()=>{
        if(localStorage.getItem(lsPrefix+'adult-check') == 1){
            setDisplay('none');
        }
    }, [adultCheckParam])

    const setAdultCheck=()=>{
        setAdultCheckParam(1);
        localStorage.setItem(lsPrefix+'adult-check', 1);
    }


    return(
        <div style={{display: display}} className='adult-check'>
            <div className='adult-check-container'>
                <h2><T text="age_limit"/></h2>
                <p><T text="age_content"/></p>
                <button onClick={()=>{setAdultCheck()}}><T text="submit"/></button>
            </div>
        </div>
    )
}
