import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Cart } from '../components/Cart';
import Service from '../components/Service';
import renderHTML from 'react-render-html';
import { Carousel } from 'react-responsive-carousel';
import { StoreContext } from '../context/StoreContext';
import T from '../components/Translate';
//import ReactGA from 'react-ga';



// function images(image){
//     return 'http://api.pp.cekaonica.com/media/'+ image + '.jpg';
// }

const Info = props => (
  
    <div className="product-info"><span className="product-info-label" >{props.label}: </span> {props.value} </div>
 
);

// function SamplePrevArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{ ...style, display: "block", background: "green" }}
//       onClick={onClick}
//     />
//   );
// }

class Product extends Component {
    static contextType = StoreContext;
    constructor(props) {
        super(props);
        this.state = {
            product: {},
            images: []
        }
        this.slider = React.createRef();
    }

    componentDidMount() {
        this.getProduct();
        //this.getGallery();
        
    }

    getProduct=()=>{
        Service.get('/products/get',{productId: this.props.match.params.id},(res)=>{
            if(res.data.products[0].images){
                this.setState({product: res.data.products[0], images: res.data.products[0].images},()=>{
                    // this.getGallery();
                });
            }else{
                this.setState({product: res.data.products[0]},()=>{
                    // this.getGallery();
                });
            }
            
        })
    }

    getGallery=()=>{
        Service.get('/products/getGallery',{productId: this.props.match.params.id},(res)=>{
            let images = res.images;
            images.unshift({image: this.state.product.image});
            this.setState({images: images});
        })
    }

    addToCart=(event)=>{
        let productId = event.target.getAttribute('data-product-id');
        console.log(productId)
        Cart.addToCart(productId);
    }

    render() {

        return(
            
            <div className="content">
                {/* <div className="left-content">

                    <Categories />
                </div> */}
                <div>
                    <div className="product-single">
                        <h2>{this.state.product.name}</h2>
                        <div>
                            <div className="product-single-images">
                                {/* <img src={this.state.product.image} className="product-single-image"/>
                                {this.state.images.map((item, index)=>{
                                    return(
                                        <img src={item.image} className="product-single-gallery-image" />
                                    )
                                })
                                }        */}
                                {/* <Banners page={"product"} productId={this.props.match.params.id}/> */}
                                <Carousel showThumbs={false} showStatus={false} interval={4000} infiniteLoop={true} autoPlay={this.state.autoplay} >
                                    <div className={this.props.page === "product" ? "product-image-container" : null} key={'main-image'}> 
                                        <img src={this.state.product.image} /> 
                                    </div> 
                                    {this.state.images.map(function (item, index) {
                                        return (
                                            <div className={this.props.page === "product" ? "product-image-container" : null} key={index}> 
                                                <img src={item} /> 
                                            </div>    
                                        )
                                    },this)}
                                </Carousel>

                                <div className="product-signgle-cart-container">
                                    <div className="product-signgle-cart">
                                        <div className="home-product-price">{Service.price(this.state.product.price)}</div>
                                        {this.context.store.type === 1 &&
                                            <div className="shop-button" onClick={this.addToCart} data-product-id={this.state.product.id}>dodaj u korpu<i className="fas fa-cart-plus"></i></div>
                                        }
                                        {this.context.store.type === 2 &&
                                            <Link to="/contact"><div className="shop-button"><T text="contactUsButton"/></div></Link>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="product-single-description">{renderHTML('<div>'+this.state.product.description+'</div>')}</div>
                        </div>
                       
                    </div>
                </div>
            </div>

        );
    }

}

export default Product;