import React, { Component, Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';
import Registration from './Registration';
import CartDisplay from './CartDisplay';
import CartDisplayMobile from './CartDisplayMobile';
import WishDisplay from './WishDisplay';
import T from '../components/Translate';
import Service from '../components/Service';
import {Auth} from '../components/Auth';
import { StoreContext } from '../context/StoreContext';

const lsPrefix = 'brntshp'+window.location.hostname.split('.')[0];

class Header extends Component {
    static contextType = StoreContext;
    constructor(props){
        super(props);
        this.state = {
            menuVisible: false,
            listShow: false,
            hamburgerShow: true,
            logoUrl: '',
            headerCustomPages: [],
            selectedCurrency: ''
        }
    }


    componentDidMount(){
        this.mobileMenu();
        this.getLogo();
        this.getHeaderCustomPages();
        setInterval(function(){
            this.forceUpdate();
        }.bind(this), 200);
        if (localStorage.getItem(lsPrefix+'currency')) {
            let cc = localStorage.getItem(lsPrefix+'currency');
            this.setState({selectedCurrency: cc})
        }
        // this.updateCartDisplay();
    }

    componentDidUpdate(prevProps) {
        
        if (this.props.location !== prevProps.location) {
            this.setState({menuVisible: false}, ()=>{
                this.mobileMenu();
            });

        }
      }
    
    componentDidUpdate(prevProps) {
        
        if (this.props.location !== prevProps.location) {
            this.setState({menuVisible: false}, ()=>{
                this.mobileMenu();
            });

        }
    }

    getLogo=()=>{
        Service.get("/store/getLogo",{}, (res) => {
            if(res.success){
                this.setState({logoUrl: res.image})
            }
        }); 
    }

    getHeaderCustomPages=()=>{
        Service.get("/store/getHeaderCustomPages",{}, (res) => {
            if(res.success){
                this.setState({headerCustomPages: res.header})
            }
        }); 
    }
    
    mobileMenu=()=>{
        let el = document.getElementById("header");
        if(!this.state.menuVisible){
            el.style.height = "60px";
            this.setState({hamburgerShow: true},()=>{
                this.setState({listShow: false})
            });
        }else{
            el.style.height = "100%";
            this.setState({listShow: true})
        }
    }

    showMenu=()=>{
        this.setState({menuVisible: !this.state.menuVisible},()=>{
            this.mobileMenu();
        })
    }

    updateCartDisplay=()=>{
        // ref function for updating the products count in the cartDisplay component
        //this.cartDisplay.current.numberOfCartProducts();
    }

    updateRegistration=()=>{
        // ref function for updating the products count in the cartDisplay component
        //this.registrationDisplay.current.update();
    }

    setCurrency=(event)=>{
        this.setState({selectedCurrency: event.target.value},()=>{
            localStorage.setItem(lsPrefix+'currency', this.state.selectedCurrency);
            Auth.setCurrency(this.state.selectedCurrency);
        })
    }

    hideHeader=()=>{
        this.setState({menuVisible: !this.state.menuVisible},()=>{
            this.mobileMenu();
        })
    }
    
    render(){
        return(
            <div className="header" id="header">
                <div className='logo color-titles'>
                    <Link to="/" ><img src={this.state.logoUrl} /></Link>
                </div>
                {window.screen.width > 600 &&
                    <ul>
                        {(this.context.store.type == 1 || this.context.store.type == 2) &&
                            <Link to="/shop"><li id="shop"><T text="headerShop"/></li></Link>
                        }
                        
                        {this.state.headerCustomPages.length > 0 &&
                            this.state.headerCustomPages.map(function(item, index){
                                return(
                                    <Link to={`/${item.slug}`}><li>{item.title}</li></Link>  
                                )
                            },this)
                        }

                        {/* Store shop */}
                        {this.context.store.type === 1 &&
                            <Link to="/terms-of-use"><li id="terms-of-use"><T text="headerTermsOfUse"/></li></Link>
                        }
                        
                        <Link to="/contact"><li id="contact"><T text="headerContact"/></li></Link> 

                        {/* Store shop */}
                        {this.context.store.type === 1 &&
                            <Fragment>
                                <Link to="/cart"><li id="cart"><CartDisplay /></li></Link>
                                <select id="currency-selector" value={this.state.selectedCurrency} onChange={this.setCurrency}>
                                    <option value="rsd" >RSD</option>
                                    <option value="eur" >EUR</option>
                                    <option value="usd" >USD</option>
                                </select> 
                                <Registration ref={this.registrationDisplay}/>
                            </Fragment>
                        }
                        
                        {/* <Link to="/lista-zelja"><WishDisplay /></Link> */}
                        {/* <Link to="/korpa"><CartDisplay /></Link> */}
                    </ul>
                }

                {window.screen.width <= 600 &&
                    <ul>
                        {this.state.hamburgerShow &&
                            <li id="hamburger" onClick={this.showMenu}><i className="fas fa-bars"></i></li>
                        }
                     
                        {this.state.listShow &&
                            <div className="mobile-header-items" >
                                {(this.context.store.type == 1 || this.context.store.type == 2) &&
                                    <Link to="/shop"><li id="shop"><T text="headerShop"/></li></Link>
                                }
                                        
                                {this.state.headerCustomPages.length > 0 &&
                                    this.state.headerCustomPages.map(function(item, index){
                                        return(
                                            <Link to={'/page/' + item.slug}><li>{item.title}</li></Link>  
                                        )
                                    },this)
                                }

                                <Link to="/terms-of-use"><li id="terms-of-use"><T text="headerTermsOfUse"/></li></Link>
                                <Link to="/contact"><li id="contact"><T text="headerContact"/></li></Link> 
                                
                                {/* Store shop */}
                                {this.context.store.type === 1 &&
                                    <Fragment>
                                        <Link to="/cart"><li id="cart"><T text="headerCart"/> <CartDisplayMobile /></li></Link>
                                        <li>
                                            <select id="currency-selector" value={this.state.selectedCurrency} onChange={this.setCurrency}>
                                                <option value="rsd" >RSD</option>
                                                <option value="eur" >EUR</option>
                                                <option value="usd" >USD</option>
                                            </select> 
                                        </li>
                                        <li className="login-display-mobile" onClick={this.hideHeader}>
                                            <Registration ref={this.registrationDisplay}/>
                                        </li>
                                    </Fragment>
                                }
                                {/* <a href="/cart" ><button><CartDisplayMobile /></button></a>  */}
                            </div>
                        }             
                    </ul>
                }
            </div>
        )
    }

}


export default withRouter(props => <Header {...props}/>);
//export default Header;